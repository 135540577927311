<template>
  <section class="dashboard">
    <router-view class="dashboard-page" :key="$route.path" />
  </section>
</template>

<script>
  export default {
    name: 'GuestLayout',
    data: () => ({
      isDashboardCollapsed: false,
    }),
  }
</script>

<style lang="scss" scoped>
  @import '/src/assets/theme/default/colors.scss';

  .dashboard {
    height: 100vh;
    display: grid;
    transition: all 0.3s ease;
    grid-template-areas: 'content content';
    grid-template-columns: min-content auto;

    &-page {
      grid-area: content;
      overflow: auto;
      background-color: $white-mist-bg;
    }
  }
</style>
