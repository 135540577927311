import AuthService from '../auth-service'
import jwtDecode from 'jwt-decode'
import Constants from '@/common/constants'

export const auth = {
  namespaced: true,
  state: { status: { loggedIn: false }, user: null },
  actions: {
    initUser: async ({ commit, dispatch, getters }) => {
      const storedUser = JSON.parse(localStorage.getItem('user'))
      if (storedUser) {
        commit('loginSuccess', storedUser)
        var u = getters.decodedUser[Constants.authKey]
        switch (u) {
          case 'OrganizationInnerAdmin': {
            await dispatch(
              'setOrgName',
              storedUser.info.companies ? storedUser.info.companies.map((_) => _.name).join(', ') : ''
            )
            break
          }
          case 'ConfigServerAdmin': {
            await dispatch('setOrgName', 'АРМ администратора')
            break
          }
        }
        return
      }
      return dispatch('logout')
    },
    login: async function ({ commit, dispatch, getters }, user) {
      try {
        const userData = await AuthService.login(user)
        debugger
        commit('loginSuccess', userData)
        var u = getters.decodedUser[Constants.authKey]
        switch (u) {
          case 'OrganizationInnerAdmin': {
            await dispatch(
              'setOrgName',
              userData.info.companies ? userData.info.companies.map((_) => _.name).join(', ') : ''
            )
            break
          }
          case 'ConfigServerAdmin': {
            dispatch('setOrgName', 'АРМ администратора')
            break
          }
        }
        return null
      } catch (e) {
        commit('loginFailure')
        return e
      }
    },
    logout({ commit }) {
      AuthService.logout()
      commit('logout')
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        (response) => {
          commit('registerSuccess')
          return Promise.resolve(response.data)
        },
        (error) => {
          commit('registerFailure')
          return Promise.reject(error)
        }
      )
    },
    setOrgName({ commit }, text) {
      commit('setOrgName', text)
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true
      state.user = user
    },
    loginFailure(state) {
      state.status.loggedIn = false
      state.user = null
    },
    logout(state) {
      state.status.loggedIn = false
      state.user = null
    },
    registerSuccess(state) {
      state.status.loggedIn = false
    },
    registerFailure(state) {
      state.status.loggedIn = false
    },
    setOrgName(state, text) {
      state.user.orgname = text
    },
  },
  getters: {
    decodedUser: (state) => (state.user?.accessToken ? jwtDecode(state.user.accessToken) : null),
  },
}
