<template>
  <div class="col-md-12 d-flex align-items-center justify-content-center">
    <div class="card card-container">
      <form-component @submit="handleLogin" :validation-schema="schema">
        <div class="card-body">
          <div class="form-group">
            <label for="username">Имя пользователя</label>
            <field name="username" type="text" class="form-control" />
            <error-message name="username" class="error-feedback text-sm text-danger" />
          </div>
          <div class="form-group">
            <label for="password">Пароль</label>
            <field name="password" type="password" class="form-control" />
            <error-message name="password" class="error-feedback text-sm text-danger" />
          </div>
        </div>
        <div class="card-footer">
          <div class="d-flex gap-2 gap-md-5 justify-content-between">
            <button-component :disabled="loading" :loading="loading">
              <span>Войти</span>
            </button-component>
          </div>

          <div v-if="message" class="form-group">
            <div class="alert alert-danger mt-2" role="alert">
              {{ message }}
            </div>
          </div>
        </div>
      </form-component>
    </div>
  </div>
</template>

<script>
  import { Form as FormComponent, Field, ErrorMessage } from 'vee-validate'
  import * as yup from 'yup'
  import ButtonComponent from '@/common/ui/ButtonComponent'
  import { mapActions, mapGetters } from 'vuex'
  import Constants from '@/common/constants'

  export default {
    name: 'Login',
    components: {
      ButtonComponent,
      FormComponent,
      Field,
      ErrorMessage,
    },
    data() {
      const schema = yup.object().shape({
        username: yup.string().required('Введите имя пользователя!'),
        password: yup.string().required('Введите пароль!'),
      })

      return {
        loading: false,
        message: '',
        schema,
      }
    },
    computed: {
      ...mapGetters({
        decodedUser: 'auth/decodedUser',
      }),
      loggedIn() {
        return this.$store.state.auth.status.loggedIn
      },
    },
    created() {
      if (this.loggedIn) {
        //this.$router.push('/profile')
        const roles = this.decodedUser[Constants.authKey]
        if (roles.includes('OrganizationInnerAdmin')) return this.$router.push({ name: 'Complexes' })
        else if (roles.includes('ServerAdmin')) return this.$router.push({ name: 'Organizations' })
        else return this.$router.push({ name: 'Login' })
      }
    },
    methods: {
      ...mapActions({
        login: 'auth/login',
      }),
      async handleLogin(user) {
        this.loading = true

        const message = await this.login(user)
        this.loading = false
        if (message) {
          this.message = message.response?.data?.message || message.message || message.toString()
        }

        const roles = this.decodedUser[Constants.authKey]
        if (roles.includes('OrganizationInnerAdmin')) return this.$router.push({ name: 'Complexes' })
        else if (roles.includes('ServerAdmin')) return this.$router.push({ name: 'Organizations' })
        else return this.$router.push({ name: 'Login' })
      },
      register() {
        this.$router.push({ name: 'Register' })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .card-container {
  }
</style>
