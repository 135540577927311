<template>
  <div>
    <page-header>
      <template #breadcrumbs>
        <router-link to="/devices">Виды оборудования</router-link>
        &raquo;
      </template>
      <h2 v-if="item">{{ item.name }}</h2>
    </page-header>
    <div class="card" v-if="item">
      <tree-table v-if="mappedData" :content="mappedData">
        <template #th>
          <th></th>
          <th>Название параметра</th>
          <th>Описание параметра</th>
          <th>Действия</th>
        </template>
        <template #row="{ item }">
          <example-row-component
            v-if="item"
            v-on="{
              'edit-param': showModal,
              'append-child': appendChild,
              'remove-param': deleteParam,
            }"
            :item="item"
            :level="0"
          />
        </template>
      </tree-table>

      <div class="card-footer">
        <div class="float-right d-flex">
          <button-component class="mr-2" @click="addParam">Добавить параметр</button-component>
          <button-component class="mr-2" :class="{ disabled: inProcess }" @click="save" v-if="!isReadOnly">
            Сохранить
          </button-component>
          <button-component kind="tertiary" @click="close">Закрыть</button-component>
        </div>
      </div>
    </div>
    <div v-show="activeItem" @click="activeItem = null" class="modal-backdrop opacity-25" />
    <div
      v-show="activeItem"
      @click.self="activeItem = null"
      :class="[
        `modal`,
        `fade`,
        {
          'show d-block': activeItem,
        },
      ]"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content shadow">
          <edit-parameter-form
            v-if="activeItem"
            @update-field="updateParam"
            @close-modal="deleteIfEmpty"
            :item="activeItem"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Constants from '../../../common/constants'
  import API from '@/common/api/index'
  import PageHeader from '@/common/components/page/PageHeader'
  import ButtonComponent from '@/common/ui/ButtonComponent'
  import TreeTable from '@/common/components/TreeTable/TreeTable'
  import ExampleRowComponent from '@/common/components/TreeTable/ExampleRowComponent'
  import EditParameterForm from '@/common/components/TreeTable/EditParameterForm'

  export default {
    name: 'DeviceTypeCard',
    components: {
      EditParameterForm,
      ExampleRowComponent,
      TreeTable,
      PageHeader,
      ButtonComponent,
    },
    props: {
      id: {
        type: String,
        required: false,
      },
    },
    data() {
      return {
        item: {
          id: this.$route.params.id,
        },
        activeItem: null,
        restrictsList: [],
        select2Settings: Constants.select2Settings,
        backUrl: '/devices',
      }
    },
    computed: {
      mappedData() {
        return this.getMappedData(this.item.parameters)
      },
    },
    created() {
      this.loadPage()
    },
    methods: {
      loadPage() {
        API.getDeviceTypeParameterTypes()
          .then((pres) => {
            this.restrictsList = pres.data
            console.log(this.restrictsList, '-------restrictsList')
            API.getDeviceType(this.item.id)
              .then((res) => {
                this.item = res.data
                console.log(this.item, '------------ deviceTypeCard item')
                //item.parameters - список параметров
                //           "parameters": [
                //   {
                //     "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                //     "name": "string",
                //     "description": "string",
                //     "parentParameterId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                //     "isRequired": true,
                //     "restricts": {
                //       "type": "String",
                //       "restricts": "string"
                //     },
                //     "childParameters": [
                //       "string" -- json
                //     ]
                //   }
                // ]
              })
              .catch((err) => {
                console.log(err.response.data)
              })
          })
          .catch((err) => {
            console.log(err.response.data)
          })
      },
      getMappedData(parameters) {
        if (!parameters?.length) return []
        return {
          rows: parameters.map((p) => {
            return {
              columns: {
                id: p.id,
                description: p.description,
                name: p.name,
              },
              childParameters: this.getMappedData(p.childParameters),
              showChildren: false,
              parentParameterId: p.parentParameterId,
            }
          }),
        }
      },
      recursiveFindParameter(item, params) {
        const id = item.columns.id
        const param = params.find((param) => param.id === id)
        if (param) {
          return param
        }
        for (const param of params) {
          if (Array.isArray(param.childParameters) && param.childParameters.length) {
            const childParameters = param.childParameters
            const neededParam = this.recursiveFindParameter(item, childParameters)
            if (neededParam) {
              return neededParam
            }
          }
        }
      },
      appendChild(item) {
        const param = this.recursiveFindParameter(item, this.item.parameters)
        const newParam = this.getEmptyParam(param.id)
        param.childParameters.push(newParam)
        this.showModal(newParam)
      },
      addParam() {
        const newParam = this.getEmptyParam(null)
        this.item.parameters.push(newParam)
        this.showModal(newParam)
      },
      getEmptyParam(parentId) {
        const newid = this.uuidv4()
        const empty = {
          id: newid,
          name: '',
          description: '',
          parentParameterId: parentId,
          type: 'SOFTWARE',
          isRequired: true,
          restricts: {
            type: 'String',
            restricts: {
              maxLength: 500,
              minLength: 1,
            },
          },
          childParameters: [],
          columns: {
            id: newid,
            description: '',
            name: '',
          },
        }
        return empty
      },
      deleteParam(item) {
        const param = this.recursiveFindParameter(item, this.item.parameters)
        let parentParam
        if (param.parentParameterId) {
          const parameter = this.recursiveFindParameter(
            {
              columns: {
                id: param.parentParameterId,
              },
            },
            this.item.parameters
          )
          parentParam = parameter.childParameters
        } else {
          parentParam = this.item.parameters
        }
        parentParam.splice(parentParam.indexOf(param), 1)
      },
      updateParam(item) {
        const param = this.recursiveFindParameter(
          {
            columns: {
              id: item.id,
            },
          },
          this.item.parameters
        )
        let parentParam
        if (param.parentParameterId) {
          const parameter = this.recursiveFindParameter(
            {
              columns: {
                id: param.parentParameterId,
              },
            },
            this.item.parameters
          )
          parentParam = parameter.childParameters
        } else {
          parentParam = this.item.parameters
        }
        parentParam.splice(parentParam.indexOf(param), 1, { ...item })
      },
      deleteIfEmpty(item) {
        if (item)
          if (!item.name || item.name.trim() === '' || !item.description || item.descriptiom.trim() === '')
            this.deleteParam(item)
        this.activeItem = null
      },
      showModal(item) {
        this.activeItem = this.recursiveFindParameter(item, this.item.parameters)
      },
      uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
          (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
        )
      },
      save() {
        if (this.inProcess) return

        const self = this

        self.inProcess = true
        const data = {
          deviceTypeId: this.item.id,
          parameters: this.item.parameters,
        }
        API.updateDeviceTypeParams(data)
          .then(() => {
            Constants.alert.fire('Сохранение', 'Успешно', 'success')
          })
          .catch((error) => {
            Constants.alert.fire('Сохранение', error.response.data, 'error')
          })
          .finally(() => (this.inProcess = false))
      },
      close() {
        this.$router.back()
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '/src/assets/theme/default/colors.scss';
  @import '/src/common/index';

  .no-search-box .select2-drop {
    .select2-search {
      display: none;
    }
  }

  .w100 .select2-container {
    width: 100% !important;
  }
  .select2::v-deep {
    .select2-container {
      width: 100% !important;
      max-width: 100% !important;
    }

    .select2-container--default .select2-selection--single {
      height: calc(2.25rem + 2px) !important;
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow {
      top: 7px !important;
    }
  }
</style>
