<template>
  <div class="content">
    <h1>Компонент таблички с данными</h1>
    <tree-table :content="tree"></tree-table>
  </div>
</template>

<script>
  import TreeTable from '@/common/components/TreeTable/TreeTable'
  import ExampleRowComponent from '@/common/components/TreeTable/ExampleRowComponent'

  export default {
    name: 'About',
    components: { TreeTable },
    data: () => ({
      tree: {
        rows: [
          {
            columns: [1, 2, 3],
            children: {
              rows: [
                {
                  columns: [1, 2, 3],
                  children: {
                    rows: [
                      {
                        columns: [1, 2, 3],
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            columns: [1, 2, 3],
            children: {
              rows: [
                {
                  columns: [1, 2, 3],
                  children: {
                    rows: [
                      {
                        columns: [1, 2, 3],
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            columns: [1, 2, 3],
            children: {
              component: ExampleRowComponent,
              rows: [
                {
                  columns: [1, 2, 3],
                  children: {
                    rows: [
                      {
                        columns: [1, 2, 3],
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            columns: [1, 2, 3],
          },
        ],
      },
    }),
  }
</script>
