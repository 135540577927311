<template>
  <section class="nav-block mb-1">
    <div :class="isCollapsed ? 'collapsed' : 'collapsible'">
      <div class="collapsed-wrapper">
        <div class="show-fixed">
          <router-link :to="node.to" class="nav-link" active-class="active">
            <i v-if="node.class" :class="[node.class, 'icon']" v-on:click="hasChildren() ? toggle($event) : null" />
            <icon-component class="icon" v-else :name="node.icon" @click="hasChildren() ? toggle() : null" />
            <p class="nav-title" @click="toggle($event)">{{ node.title }}</p>
            <icon-component
              :class="['chevron', { chevron_rotated: expanded }]"
              v-if="hasChildren()"
              name="chevron"
              @click="hasChildren() ? toggle($event) : null"
            />
          </router-link>
          <ul v-if="hasChildren()" v-show="expanded" class="nav nav-pills nav-sidebar flex-column nav-submenu">
            <li v-for="item in node.items" :key="item.title">
              <tree-node :node="item"></tree-node>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import IconComponent from './IconComponent'
  export default {
    name: 'TreeNode',
    components: { IconComponent },
    props: {
      node: Object,
      isCollapsed: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        expanded: undefined,
      }
    },
    mounted() {
      this.expanded = this.node.expanded || false
      this.loadState()
    },
    methods: {
      hasChildren() {
        return Array.isArray(this.node?.items)
      },
      toggle(e) {
        if (this.hasChildren()) {
          e.preventDefault()
          this.expanded = !this.expanded
          this.saveState()
          return false
        }
        return true
      },
      loadState() {
        const expanded = localStorage.getItem(this.getKey())
        if (!expanded) {
          return
        }
        this.expanded = expanded === 'true'
      },
      saveState() {
        localStorage.setItem(this.getKey(), this.expanded)
      },
      getKey() {
        return `tree-node:${this.node.to}`
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '/src/assets/theme/default/colors.scss';
  .icon {
    width: 20px;
  }
  .nav-block {
    position: relative;
    height: 35px;

    .collapsed {
      position: relative;
      .collapsed-wrapper {
        position: sticky;

        .show-fixed {
          position: fixed;
          background: $main;
          border-radius: 0.25rem;

          .nav-title,
          .chevron {
            display: none;
          }
          &:hover {
            .nav-title,
            .chevron {
              display: inline-block;
            }

            .nav-submenu {
              background-color: $main;
              display: block;
            }
          }

          .nav-submenu {
            display: none;
          }
        }
      }
    }
  }
  .nav-pills {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .nav-link {
      display: flex;
      align-items: center;
      gap: 8px;
      font-weight: 600;
      font-size: 16px;
      line-height: 1.2;
      height: 32px;
      color: $white;
      white-space: nowrap;

      &:not(.active):hover {
        color: $white-mist-bg;
        background: #007bff;
        border: 0;
        border-radius: 0.25rem;
      }

      .nav-title {
        white-space: nowrap;
        letter-spacing: 0.5px;
      }
      .chevron {
        margin-left: auto;

        &_rotated {
          transform: rotate(180deg);
        }
      }
    }
  }

  .nav-pills.nav-submenu {
    margin-left: 10px;
    padding-top: 10px;
  }
</style>
