<template>
  <div class="content">
    <h6>Выберите одну из дочерних веток в дереве слева</h6>
  </div>
</template>

<script>
  export default {
    name: 'ContentView',
  }
</script>

<style scoped>
  .content {
    padding: 16px;
  }
</style>
