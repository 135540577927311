<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>Ключ лицензии</label>
              <div>
                <input class="form-control" v-model="licenseKey" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer d-flex">
        <button class="btn btn-primary" v-on:click="register" v-if="online">Импортировать ДК</button>
        <button class="btn btn-primary" v-on:click="register" v-if="!online">Создать запрос на лицензию</button>
        <button class="btn btn-default" v-on:click="cancel">Отмена</button>
      </div>
    </div>
  </div>
</template>

<script>
  import api from '../../../common/api'
  import Constants from '../../../common/constants'

  export default {
    name: 'WriteLicense',
    props: {
      online: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        licenseKey: '',
      }
    },
    methods: {
      register() {
        if (this.online) {
          api
            .registerLicenseOnline({
              licenseKey: this.licenseKey,
            })
            .then(() => {
              Constants.alert.fire('Загрузка лицензии', 'Успешно', 'success')
              this.$emit('onSuccessLoadLicense', {})
            })
            .catch((error) => {
              Constants.alert.fire('Загрузка лицензии', error.response.data, 'error')
            })
        } else {
          api
            .generateLicenseRequest({
              licenseKey: this.licenseKey,
            })
            .then(() => {
              this.$emit('onSuccessLoadLicense', {})
            })
            .catch((error) => {
              Constants.alert.fire('Формирование запроса на лицензию', error.response.data, 'error')
            })
        }
      },
      cancel() {
        this.$emit('cancelLoadLicense', {})
      },
    },
  }
</script>
