import { createStore } from 'vuex'
import { auth } from '@/modules/auth/store'

const initState = localStorage.getItem('state')

const store = createStore({
  state: {
    showMenu: false,
  },
  mutations: {
    menuToggle(state) {
      state.showMenu = !state.showMenu
    },
    closeMenu(state) {
      return (state.showMenu = false)
    },
  },
  actions: {
    toggleMenuState({ commit }) {
      commit('menuToggle')
    },
    menuClose({ commit }) {
      commit('closeMenu')
    },
  },
  getters: {
    getMenuState(state) {
      return state.showMenu
    },
  },
  modules: {
    auth,
  },
})
if (initState) {
  store.replaceState(JSON.parse(localStorage.getItem('state')))
}
store.subscribeAction({
  after: (action, state) => {
    localStorage.setItem('state', JSON.stringify(state))
  },
})
export default store
