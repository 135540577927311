<template>
  <div class="page-content">
    <page-header>
      <h2>Организации</h2>
      <template #actions>
        <div class="d-flex actions">
          <button-component kind="tertiary" @click="modals.filter = !modals.filter">
            <template #prepend>
              <icon-component class="mr-2" name="filter" />
            </template>
            Фильтр
          </button-component>
        </div>
      </template>
    </page-header>
    <section :class="{ content: true }">
      <template v-if="page.data">
        <div v-if="page.data.length" class="table-responsive">
          <table class="table-custom table">
            <thead>
              <tr>
                <th class="col-table-1">Наименование организации</th>
                <th class="col-table-1">Домофонные комплексы</th>
                <th class="col-table-1">Пользователи</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in page.data" :key="item.id">
                <td>{{ item.name }}</td>
                <td>
                  <div v-for="complex in item.intercomComplexes" :key="complex.id">
                    {{ complex.name }}
                  </div>
                </td>
                <td>
                  <a class="ml-2" href="#" title="Добавить пользователя">
                    <i @click="onAddUser(item.id)" class="fas fa-plus text-success" />
                  </a>
                  <div v-for="user in item.users" :key="user.id">
                    {{ user.userName }}{{ user.description ? '(' + user.description + ')' : '' }}
                    <a title="Сменить пароль" class="btn" @click="onChangeUserPwd(user, item)">
                      <i class="fas fa-key text-warning" />
                    </a>
                    <a title="Удалить" class="btn" @click="onDeleteUser(user.id, idx)">
                      <i class="fas fa-trash text-danger" />
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="page.totalPages > 1">
            <Pagination :page-count="page.totalPages" :current-page="page.pageNumber" @change="onPageChange($event)" />
          </div>
        </div>
        <div v-else>
          <empty-list-message />
        </div>
      </template>

      <section class="modals">
        <transition name="filter">
          <div class="filter" v-if="modals.filter">
            <organizations-filters
              v-bind:filters="filters"
              v-on:apply="onFiltersApply($event)"
              v-on:reset="onFiltersReset($event)"
            />
          </div>
        </transition>
        <transition name="addUser">
          <div class="filter" v-if="modals.addUser" style="width: 500px">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Имя пользователя</label>
                      <div>
                        <input class="form-control" v-model="newuser.userName" />
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Описание</label>
                      <div>
                        <input class="form-control" v-model="newuser.description" />
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Пароль</label>
                      <div>
                        <input class="form-control" v-model="newuser.password" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="float-right d-flex">
                  <button-component class="mr-2" @click="saveUser">Сохранить</button-component>
                  <button-component kind="tertiary" @click="closeUser">Закрыть</button-component>
                </div>
              </div>
            </div>
          </div>
        </transition>
        <transition name="editUser">
          <div class="filter" v-if="modals.editUser" style="width: 500px">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Имя пользователя</label>
                      <div>
                        <input class="form-control" v-model="curuser.userName" />
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Описание</label>
                      <div>
                        <input class="form-control" v-model="curuser.description" />
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Пароль</label>
                      <div>
                        <input class="form-control" v-model="curuser.password" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="float-right d-flex">
                  <button-component class="mr-2" @click="saveEditUser">Сохранить</button-component>
                  <button-component kind="tertiary" @click="closeEditUser">Закрыть</button-component>
                </div>
              </div>
            </div>
          </div>
        </transition>
        <transition name="changeUserPwd">
          <div class="filter" v-if="modals.changeUserPwd" style="width: 500px">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Введите новый пароль</label>
                      <div>
                        <input class="form-control" v-model="curuser.password" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="float-right d-flex">
                  <button-component class="mr-2" @click="changePwd">Отправить</button-component>
                  <button-component kind="tertiary" @click="closePwd">Отмена</button-component>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </section>
    </section>
  </div>
</template>

<script>
  import API from '@/common/api'
  import OrganizationsFilters from '../components/OrganizationsFilter.vue'
  import Constants from '../../../common/constants'
  import ButtonComponent from '@/common/ui/ButtonComponent'
  import PageHeader from '@/common/components/page/PageHeader'
  import IconComponent from '@/common/ui/IconComponent'
  import EmptyListMessage from '../../../common/components/EmptyListMessage'

  export default {
    name: 'Organizations',
    components: {
      EmptyListMessage,
      IconComponent,
      PageHeader,
      ButtonComponent,
      OrganizationsFilters,
    },
    data() {
      return {
        request: {
          pageNumber: 0,
          pageSize: Constants.pageSize,
        },
        filters: {
          search: '',
        },
        page: {},
        modals: {
          filter: false,
          addUser: false,
          editUser: false,
          changeUserPwd: false,
        },
        newuser: {},
        curuser: {},
      }
    },
    mounted() {
      this.loadPage()
    },
    methods: {
      onPageChange(pageNumber) {
        this.request.pageNumber = pageNumber
        this.loadPage()
      },
      loadPage() {
        const self = this
        const request = {
          ...this.request,
          ...this.filters,
        }
        API.getCompanyList(request).then(function (response) {
          console.log(response, '----------getCompanyList')
          self.page = {}
          self.$nextTick(function () {
            self.page = response.data
          })
        })
      },
      onFiltersApply(filters) {
        this.filters = filters
        this.request.pageNumber = 0
        this.loadPage()
      },
      onFiltersReset(filters) {
        this.filters = filters || {}
        this.request.pageNumber = 0
        this.loadPage()
      },
      onAddUser(orgId) {
        this.modals.addUser = true
        this.newuser = { companyIds: [orgId] }
      },
      onEditUser(id) {
        this.modals.editUser = true
        this.curuser = id //TODO
      },
      onChangeUserPwd(user, org) {
        this.modals.changeUserPwd = true
        this.curuser = user
        this.curuser.companyIds = [org.id]
      },
      onDeleteUser(id) {
        Constants.alert.fire(Constants.confirmOptions.delete).then((res) => {
          if (res.isConfirmed) {
            API.deleteUser(id)
              .then(() => {
                Constants.alert.fire('Удаление пользователя', 'Успешно', 'success')
                this.loadPage()
              })
              .catch((error) => {
                Constants.alert.fire('Удаление пользователя', error.response.data, 'error')
              })
          }
        })
      },
      saveUser() {
        API.saveUser(this.newuser)
          .then(() => {
            Constants.alert.fire('Добавление пользователя', 'Успешно', 'success')
            this.loadPage()
            this.modals.addUser = false
            this.newuser = {}
          })
          .catch((error) => {
            Constants.alert.fire('Добавление пользователя', error.response.data, 'error')
          })
      },
      saveEditUser() {
        API.putUser(this.curuser)
          .then(() => {
            Constants.alert.fire('Редактирование пользователя', 'Успешно', 'success')
            this.loadPage()
            this.modals.editUser = false
            this.curuser = {}
          })
          .catch((error) => {
            Constants.alert.fire('Редактирование пользователя', error.response.data, 'error')
          })
      },
      changePwd() {
        API.updateUser(this.curuser)
          .then(() => {
            Constants.alert.fire('Сброс пароля', 'Успешно', 'success')
            this.loadPage()
            this.modals.changeUserPwd = false
            this.curuser = null
          })
          .catch((error) => {
            Constants.alert.fire('Сброс пароля', error.response.data, 'error')
          })
      },
      closeUser() {
        this.modals.addUser = false
        this.newuser = {}
      },
      closePwd() {
        this.modals.changeUserPwd = false
        this.curuser = null
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '/src/assets/theme/default/colors.scss';
  @import '/src/common/index';

  .page-content {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }
  .content {
    padding: 10px 12px;
    position: relative;
    overflow-y: visible;

    .filter {
      position: absolute;
      width: 350px;
      height: auto;
      top: 0;
      right: 0;
      overflow: hidden;
      background-color: transparent;
      opacity: 1;
      transition: opacity 0.2s;
      z-index: 1000;
    }

    .filter-enter, .filter-leave-to /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0;
    }
  }
</style>
