<template>
  <div>
    <page-header>
      <h2>Профиль</h2>
    </page-header>
  </div>
</template>

<script>
  import PageHeader from '@/common/components/page/PageHeader'

  export default {
    name: 'Profile',
    components: {
      PageHeader,
    },
    created() {},
  }
</script>
