<template>
  <div>
    <component :is="layoutComponentName">
      <router-view />
    </component>
  </div>
</template>

<script>
  import DefaultLayout from '@/layouts/DefaultLayout.vue'
  import DashboardLayout from '@/common/ui/layout/DashboardLayout'
  import GuestLayout from '@/common/ui/layout/GuestLayout'
  import AuthLayout from '@/common/ui/layout/AuthLayout'
  import { mapGetters } from 'vuex'

  export default {
    name: 'App',
    components: {
      DefaultLayout,
      DashboardLayout,
      GuestLayout,
      AuthLayout,
    },
    data() {
      return {}
    },
    computed: {
      ...mapGetters({
        user: 'auth/decodedUser',
      }),
      layoutComponentName() {
        if (!this.$store.state.auth.user) return this.$route.meta?.layout || 'GuestLayout'
        else return this.$route.meta?.layout || 'DashboardLayout'
      },
    },
    mounted() {
      this.checkAuth()
    },
    methods: {
      checkAuth() {
        console.log(this.$route.meta?.isPublic, '!!!!!!!!!!-----------')
        if (!this.$route.meta?.isPublic) {
          return this.$store.dispatch('auth/initUser')
        }
      },
    },
  }
</script>
