import Swal from 'sweetalert2'

export default {
  emptySelectOptionText: '-- не выбрано --',
  validatorMessageEmpty: 'Введите данные',
  pageSize: 10,
  confirmOptions: {
    delete: {
      title: 'Удаление',
      text: 'Вы действительно хотите удалить эту запись?',
      confirmButtonText: 'Удалить',
      cancelButtonText: 'Отмена',
      showCancelButton: true,
      icon: 'warning',
    },
    activate: {
      title: 'Активация',
      text: 'Вы уверены?',
      confirmButtonText: 'Активировать',
      cancelButtonText: 'Отмена',
      showCancelButton: true,
      icon: 'warning',
    },
    emailChanged: {
      title: 'Сброс пароля',
      text: 'Вы изменили адрес электронной почты. Хотите ли вы сбросить пароль?',
      confirmButtonText: 'Сгенерить новый',
      cancelButtonText: 'Оставить текущий',
      showCancelButton: true,
      icon: 'warning',
    },
  },

  select2Settings: {
    language: 'ru',
    minimumResultsForSearch: 20,
  },
  yesNoList: [
    {
      id: null,
      text: '-- не выбрано --',
    },
    {
      id: true,
      text: 'Да',
    },
    {
      id: false,
      text: 'Нет',
    },
  ],
  phoneInputMask: '+7 (999) 999-99-99',
  macInputMask: 'xx:xx:xx:xx:xx:xx',
  alert: Swal.mixin({
    //customClass: {
    //  confirmButton: 'btn btn-success',
    //  cancelButton: 'btn btn-danger'
    //},
    //buttonsStyling: false
  }),
  authKey: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role',
}

export const mapParameterFieldToForm = {
  // "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  // "name": "string",
  // "description": "string",
  // "parentParameterId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  // "isRequired": true,
  // "restricts": {
  //   "type": "String",
  //   "restricts": "string"
  //  },
  // "childParameters": [
  //   "string" -- json
  // ]
  //id: {
  //  label: 'ID параметра',
  //  disabled: true,
  //  field: 'input',
  //},
  name: {
    label: 'Название параметра',
    field: 'input',
  },
  description: {
    label: 'Описание параметра',
    field: 'input',
  },
  //parentParameterId: {
  //  label: 'ID родительского параметра',
  //  disabled: true,
  //  field: 'input',
  //},
  isRequired: {
    label: 'Поле обязательно?',
    field: 'input',
    type: 'checkbox',
  },
  type: {
    label: 'Тип параметра',
    field: 'select',
    options: ['SOFTWARE', 'HARDWARE'],
  },
  restricts: {
    label: 'Ограничения',
    disabled: true,
    field: 'input',
  },
}
