import axios from 'axios'
import runtimeConfig from '@/common/runtime-config'

axios.interceptors.request.use(
  (config) => {
    config.params = { ...config.params }
    refreshAuthorizationHeader()
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export function configAxios() {
  axios.defaults.baseURL = runtimeConfig.apiBaseUrl
}

export default function refreshAuthorizationHeader() {
  const token = localStorage.getItem('token')
  if (token) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
  } else {
    delete axios.defaults.headers.common['Authorization']
  }
}

refreshAuthorizationHeader()

const commandAxios = axios.create({
  baseURL: '',
})

export { commandAxios }
