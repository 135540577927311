<template>
  <div class="page-content">
    <page-header>
      <h2>Виды оборудования</h2>
      <template #actions>
        <div class="d-flex actions">
          <button-component class="mr-2" @click="addDev()">Добавить</button-component>
        </div>
      </template>
    </page-header>
    <div class="content table-responsive" v-if="devices.length > 0">
      <table class="table-custom table">
        <thead>
          <tr>
            <th class="col-md-4">Наименование</th>
            <th class="col-md-4">Описание</th>
            <th class="col-md-2"></th>
            <th class="col-md-1"></th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(dev, idx) in devices" v-bind:key="dev.id">
            <td v-if="dev.id === '' || devEditId === dev.id">
              <input type="text" class="form-control" v-model="dev.name" />
            </td>
            <td v-else>{{ dev.name }}</td>
            <td v-if="dev.id === '' || devEditId === dev.id">
              <input type="text" class="form-control" v-model="dev.description" />
            </td>
            <td v-else>{{ dev.description }}</td>
            <td>
              <button-component v-if="dev.id !== ''" class="ml-2" @click="editParams(dev.id)">
                Параметры
              </button-component>
            </td>
            <td class="table-action-cell">
              <button class="btn" v-on:click="editDev(dev.id)" v-if="devEditId === -1">
                <i class="fas fa-pen"></i>
              </button>
              <button class="btn" v-on:click="deleteDev(dev.id, idx)">
                <i class="fas fa-trash-alt text-danger" />
              </button>
              <button class="btn" v-on:click="saveDev(dev)" v-if="devEditId === dev.id">
                <i class="fas fa-check text-success" />
              </button>
              <button class="btn" v-on:click="cancelEdit()" v-if="devEditId === dev.id">
                <i class="fas fa-undo text-danger" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
      <empty-list-message />
    </div>
  </div>
</template>

<script>
  import API from '@/common/api'
  import PageHeader from '@/common/components/page/PageHeader'
  import EmptyListMessage from '../../../common/components/EmptyListMessage'
  import Constants from '../../../common/constants'
  import ButtonComponent from '@/common/ui/ButtonComponent'

  export default {
    name: 'Devices',
    components: {
      EmptyListMessage,
      PageHeader,
      ButtonComponent,
    },
    data() {
      return {
        devices: [],
        inProcess: false,
        devEditId: -1,
      }
    },
    created() {
      this.loadPage()
    },
    methods: {
      loadPage() {
        API.getDeviceTypeList().then((response) => {
          this.devices = response.data
        })
      },
      addDev() {
        this.devices.unshift({ id: '' })
        this.devEditId = ''
      },
      editDev(id) {
        this.devEditId = id
      },
      cancelEdit() {
        this.loadPage()
        this.devEditId = -1
      },
      deleteDev(id, idx) {
        if (id !== '') {
          Constants.alert.fire(Constants.confirmOptions.delete).then((res) => {
            if (res.isConfirmed) {
              API.deleteDeviceType(id)
              this.devEditId = -1
            }
          })
        } else {
          this.devices.splice(idx, 1)
          this.devEditId = -1
        }
      },
      saveDev(dev) {
        API.saveDeviceType(dev)
          .then(() => {
            Constants.alert.fire('Сохранение вида оборудования', 'Успешно', 'success')
            this.loadPage()
            this.devEditId = -1
          })
          .catch((error) => {
            Constants.alert.fire('Сохранение вида оборудования', error.response.data, 'error')
          })
      },
      editParams(id) {
        this.$router.push({ name: 'DeviceTypeCard', params: { id: id } })
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '/src/assets/theme/default/colors.scss';
  @import '/src/common/index';
  .filter {
    position: absolute;
    width: 350px;
    height: auto;
    top: 0;
    right: 0;
    overflow: hidden;
    background-color: transparent;
    opacity: 1;
    transition: opacity 0.2s;
    z-index: 1000;
  }

  .filter-enter, .filter-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>
