<template>
  <div class="emptyMessage">Список пуст</div>
</template>

<script>
  export default {
    name: 'EmptyListMessage',
  }
</script>

<style lang="scss" scoped>
  .emptyMessage {
    padding: 24px 48px;
  }
</style>
