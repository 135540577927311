<template>
  <nav class="navbar">
    <div class="navbar__menu">
      <icon-component name="hamburger" @click="$emit('collapse-toggle')" />
    </div>
    <div>
      <h1 class="title">{{ $store.state.auth.user ? $store.state.auth.user.orgname : '' }}</h1>
      <div class="user" v-if="$store.state.auth.user">
        <span class="name">{{ $store.state.auth.user.userName }}</span>
        <span v-if="$store.state.auth.user.isExpired">expired</span>
      </div>
    </div>

    <!-- Right navbar links -->

    <div class="logout" v-if="$store.state.auth.user">
      <icon-component class="logout-icon" name="logout" @click="logout" />
    </div>

    <div class="login ml-auto" v-else>
      <router-link to="/login">Войти</router-link>
    </div>
  </nav>
</template>

<script>
  import IconComponent from '@/common/ui/IconComponent'

  export default {
    name: 'HeaderComponent',
    components: { IconComponent },
    data() {
      return {
        header: localStorage.getItem('orgName'),
      }
    },
    created() {
      //console.log(this.$store.state.auth.user, '---headercomponent')
    },
    methods: {
      logout() {
        this.$store.dispatch('auth/logout')
        this.$router.push('/login')
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '/src/assets/theme/default/colors.scss';

  .navbar {
    display: flex;
    gap: 20px;
    align-items: center;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.08);
    border: none;
    padding-right: 20px;
    background: $main-gradient;
    color: $white;
    justify-content: flex-start;

    &__menu {
      width: 64px;
      padding: 12px 0;
      cursor: pointer;
    }
    &__logo {
      margin-right: 10px;
      img {
        max-height: 50px;
      }
    }

    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      letter-spacing: 2px;
      line-height: 24px;

      text-transform: uppercase;
      margin: 0 auto 0 0;
      justify-content: center;
    }

    .user {
      font-weight: 500;
      cursor: pointer;
      display: inline-block;
    }

    .logout {
      margin-right: 0;
      margin-left: auto;
      cursor: pointer;
    }

    .user,
    .logout,
    .login {
      &:hover {
        color: rgba($white, 0.8);
      }
    }
  }
</style>
