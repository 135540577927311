<template>
  <section class="page-header">
    <div class="breadcrumbs">
      <slot v-if="$slots.breadcrumbs" name="breadcrumbs" />
    </div>
    <div class="header-content">
      <div class="title"><slot /></div>
      <div class="actions">
        <slot v-if="$slots.actions" name="actions" />
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'PageHeader',
  }
</script>

<style lang="scss" scoped>
  @import 'src/assets/theme/default/colors';

  .page-header {
    padding: 24px 48px;
    background-color: $white;
    .breadcrumbs {
      color: $gray-dark;
      font-size: 14px;
      line-height: 120%;
    }
    .header-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        color: $black;
        font-weight: bold;
        font-size: 24px;
        line-height: 120%;
      }
    }
  }
</style>
