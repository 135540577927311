<template>
  <div class="page-content">
    <page-header></page-header>
    <div class="card">
      <div class="col-md-12">
        <div class="card card-container">
          <div class="card-header">
            <h4>Настройки basic аутентификации для устройств</h4>
          </div>
          <div class="card-body">
            <div class="form-group required">
              <label for="server" class="control-label">Логин</label>
              <input v-model="basicAuth.login" type="text" class="form-control" />
            </div>
            <div class="form-group required">
              <label for="basicAuth.password" class="control-label">Пароль</label>
              <input v-model="basicAuth.password" type="password" class="form-control" id="basicAuth.password" />
            </div>
          </div>

          <div class="card-footer">
            <div class="float-right d-flex">
              <button-component class="mr-2" :class="{ disabled: inProcess }" @click="saveBasicAuth">
                Сохранить
              </button-component>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="col-md-12">
        <div class="card card-container">
          <div class="card-header">
            <h4>Настройки синхронизации типов устройств с центральным сервером</h4>
          </div>
          <div class="card-body">
            <div class="form-group required">
              <label for="server" class="control-label">Интервал обновления (в минутах)</label>
              <input v-model="deviceTypeSchedule.intervalMinutes" type="number" class="form-control" />
            </div>
            <div class="form-check required">
              <input
                v-model="deviceTypeSchedule.enable"
                type="checkbox"
                class="form-check-input"
                id="deviceTypeSchedule.enable"
              />
              <label for="deviceTypeSchedule.enable" class="control-label">Включена</label>
            </div>
          </div>

          <div class="card-footer">
            <div class="float-right d-flex">
              <button-component
                class="mr-2"
                :class="{ disabled: inProcess }"
                @click="refreshDeviceTypeSchedule"
                v-if="deviceTypeSchedule.enable"
              >
                Запустить синхронизацию
              </button-component>
              <button-component class="mr-2" :class="{ disabled: inProcess }" @click="saveDeviceTypeSchedule">
                Сохранить
              </button-component>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="col-md-12">
        <div class="card card-container">
          <div class="card-header">
            <h4>Настройки синхронизации домофонных комплексов с центральным сервером</h4>
          </div>
          <div class="card-body">
            <div class="form-group required">
              <label for="server" class="control-label">Интервал обновления (в минутах)</label>
              <input v-model="complexesSyncSchedule.intervalMinutes" type="number" class="form-control" />
            </div>
            <div class="form-check required">
              <input
                v-model="complexesSyncSchedule.enable"
                type="checkbox"
                class="form-check-input"
                id="complexesSyncSchedule.enable"
              />
              <label for="complexesSyncSchedule.enable" class="control-label">Включена</label>
            </div>
          </div>

          <div class="card-footer">
            <div class="float-right d-flex">
              <button-component
                class="mr-2"
                :class="{ disabled: inProcess }"
                @click="refreshComplexSync"
                v-if="complexesSyncSchedule.enable"
              >
                Запустить синхронизацию
              </button-component>
              <button-component class="mr-2" :class="{ disabled: inProcess }" @click="saveComplexSyncSchedule">
                Сохранить
              </button-component>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="col-md-12">
        <div class="card card-container">
          <div class="card-header">
            <h4>Настройки камеры</h4>
          </div>
          <div class="card-body">
            <div class="form-group required">
              <label for="server" class="control-label">Логин по умолчанию доступа к камере</label>
              <input v-model="ipCam.innerLogin" type="text" class="form-control" />
            </div>
            <div class="form-group required">
              <label for="basicAuth.password" class="control-label">Пароль по умолчанию доступа к камере</label>
              <input v-model="ipCam.innerPassword" type="password" class="form-control" id="basicAuth.password" />
            </div>
          </div>

          <div class="card-body">
            <div class="form-group required">
              <label for="server" class="control-label">Диапазон Ip-адресов, выдываемых камерам</label>
              <input v-model="ipCam.ipAddress" type="text" class="form-control" disabled />
            </div>
            <div class="form-group required">
              <label for="server" class="control-label">Маска</label>
              <input v-model="ipCam.mask" type="text" class="form-control" disabled />
            </div>
            <div class="form-group required">
              <label for="server" class="control-label">Список IP-адресов - исключений</label>
              <input v-model="ipCam.excludeIpAddresses" type="text" class="form-control" />
            </div>
          </div>

          <div class="card-footer">
            <div class="float-right d-flex">
              <button-component class="mr-2" :class="{ disabled: inProcess }" @click="saveIpCamSettings">
                Сохранить
              </button-component>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import API from '@/common/api'
  import ButtonComponent from '@/common/ui/ButtonComponent'
  import PageHeader from '@/common/components/page/PageHeader'
  import Constants from '../../../common/constants'

  export default {
    name: 'Settings',
    components: {
      PageHeader,
      ButtonComponent,
    },
    data() {
      return {
        basicAuth: {},
        deviceTypeSchedule: {},
        userFlatSyncSchedule: {},
        housesSyncSchedule: {},
        complexesSyncSchedule: {},
        ipCam: {},
        inProcess: false,
      }
    },
    mounted() {
      this.loadPage()
    },
    methods: {
      loadPage() {
        const self = this
        API.getBasicAuthSettings()
          .then((resp) => {
            const { data } = resp
            self.basicAuth = data
          })
          .catch((e) => {
            console.log(e)
          })
        API.getDeviceTypeScheduleSettings()
          .then((resp) => {
            const { data } = resp
            self.deviceTypeSchedule = data
          })
          .catch((e) => {
            console.log(e)
          })
        API.getUserFlatSyncScheduleSettings()
          .then((resp) => {
            const { data } = resp
            self.userFlatSyncSchedule = data
          })
          .catch((e) => {
            console.log(e)
          })
        API.getHousesSyncScheduleSettings()
          .then((resp) => {
            const { data } = resp
            self.housesSyncSchedule = data
          })
          .catch((e) => {
            console.log(e)
          })
        API.getComplexSyncScheduleSettings()
          .then((resp) => {
            const { data } = resp
            self.complexesSyncSchedule = data
          })
          .catch((e) => {
            console.log(e)
          })
        API.getIpCamSettings()
          .then((resp) => {
            const { data } = resp
            self.ipCam = data
            self.ipCam.excludeIpAddresses = this.ipCam.excludeIpAddresses.join(',')
            API.getIpCamCrendSettings()
              .then((resp) => {
                self.ipCam.innerLogin = resp.data.innerLogin
                self.ipCam.innerPassword = resp.data.innerPassword
              })
              .catch((e) => {
                console.log(e)
              })
          })
          .catch((e) => {
            console.log(e)
          })
      },
      saveIpCamSettings() {
        this.ipCam.excludeIpAddresses = this.ipCam.excludeIpAddresses.split(',')
        API.saveIpCamSettings(this.ipCam)
          .then(() => {
            API.saveIpCamCrendSettings(this.ipCam)
              .then(() => {
                Constants.alert.fire('Сохранение настроек камер', 'Успешно', 'success')
                this.loadPage()
                this.devEditId = -1
              })
              .catch((error) => {
                Constants.alert.fire('Сохранение настроек камер', error.response.data, 'error')
              })
          })
          .catch((error) => {
            Constants.alert.fire('Сохранение настроек камер', error.response.data, 'error')
          })
      },
      saveBasicAuth() {
        API.saveBasicAuthSettings(this.basicAuth)
          .then(() => {
            Constants.alert.fire('Сохранение настроек аутентификации для устройств', 'Успешно', 'success')
            this.loadPage()
            this.devEditId = -1
          })
          .catch((error) => {
            Constants.alert.fire('Сохранение настроек аутентификации для устройств', error.response.data, 'error')
          })
      },
      saveDeviceTypeSchedule() {
        API.saveDeviceTypeScheduleSettings(this.deviceTypeSchedule)
          .then(() => {
            Constants.alert.fire(
              'Сохранение настроек синхронизации видов оборудования с центральным сервером',
              'Успешно',
              'success'
            )
            this.loadPage()
          })
          .catch((error) => {
            Constants.alert.fire(
              'Сохранение настроек синхронизации видов оборудования с центральным сервером',
              error.response.data,
              'error'
            )
          })
      },
      refreshDeviceTypeSchedule() {
        API.refreshDeviceTypeSync()
          .then(() => {
            Constants.alert.fire('Запуск синхронизации видов оборудования с центральным сервером', 'Успешно', 'success')
            this.loadPage()
          })
          .catch((error) => {
            Constants.alert.fire(
              'Запуск синхронизации видов оборудования с центральным сервером',
              error.response.data,
              'error'
            )
          })
      },
      saveUserFlatSyncSchedule() {
        API.saveUserFlatSyncScheduleSettings(this.userFlatSyncSchedule)
          .then(() => {
            Constants.alert.fire(
              'Сохранение настроек синхронизации пользователей МП с центральным сервером',
              'Успешно',
              'success'
            )
            this.loadPage()
          })
          .catch((error) => {
            Constants.alert.fire(
              'Сохранение настроек синхронизации пользователей МП с центральным сервером',
              error.response.data,
              'error'
            )
          })
      },
      refreshUserFlatSync() {
        API.refreshUserFlatSync()
          .then(() => {
            Constants.alert.fire('Запуск синхронизации пользователей МП с центральным сервером', 'Успешно', 'success')
            this.loadPage()
          })
          .catch((error) => {
            Constants.alert.fire(
              'Запуск синхронизации пользователей МП с центральным сервером',
              error.response.data,
              'error'
            )
          })
      },
      saveHousesSyncSchedule() {
        API.saveHousesSyncScheduleSettings(this.housesSyncSchedule)
          .then(() => {
            Constants.alert.fire('Сохранение настроек синхронизации домов с центральным сервером', 'Успешно', 'success')
            this.loadPage()
          })
          .catch((error) => {
            Constants.alert.fire(
              'Сохранение настроек синхронизации домов с центральным сервером',
              error.response.data,
              'error'
            )
          })
      },
      refreshHousesSync() {
        API.refreshHousesSync()
          .then(() => {
            Constants.alert.fire('Запуск синхронизации домов с центральным сервером', 'Успешно', 'success')
            this.loadPage()
          })
          .catch((error) => {
            Constants.alert.fire('Запуск синхронизации домов с центральным сервером', error.response.data, 'error')
          })
      },
      saveComplexSyncSchedule() {
        API.saveComplexSyncScheduleSettings(this.complexesSyncSchedule)
          .then(() => {
            Constants.alert.fire(
              'Сохранение настроек синхронизации домофонных комплексов с центральным сервером',
              'Успешно',
              'success'
            )
            this.loadPage()
          })
          .catch((error) => {
            Constants.alert.fire(
              'Сохранение настроек синхронизации домофонных комплексов с центральным сервером',
              error.response.data,
              'error'
            )
          })
      },
      refreshComplexSync() {
        API.refreshComplexSync()
          .then(() => {
            Constants.alert.fire(
              'Запуск синхронизации домофонных комплексов с центральным сервером',
              'Успешно',
              'success'
            )
            this.loadPage()
          })
          .catch((error) => {
            Constants.alert.fire(
              'Запуск синхронизации домофонных комплексов с центральным сервером',
              error.response.data,
              'error'
            )
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '/src/assets/theme/default/colors.scss';
  @import '/src/common/index';

  .page-content {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }
  .content {
    padding: 10px 12px;
    position: relative;
    overflow-y: visible;

    .filter {
      position: absolute;
      width: 350px;
      height: auto;
      top: 0;
      right: 0;
      overflow: hidden;
      background-color: transparent;
      opacity: 1;
      transition: opacity 0.2s;
      z-index: 1000;
    }

    .filter-enter, .filter-leave-to /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0;
    }
  }
</style>
