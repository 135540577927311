<template>
  <div class="col-md-12">
    <div class="card card-container">
      <h1>Анкета нового пользователя</h1>
      <form-component @submit="handleRegister" :validation-schema="schema">
        <div v-if="!successful">
          <div class="form-group required">
            <label for="name" class="control-label">Название организации</label>
            <field name="name" type="text" class="form-control" />
            <error-message name="name" class="error-feedback" />
          </div>
          <div class="form-group required">
            <label for="email" class="control-label">Email</label>
            <field name="email" type="email" class="form-control" />
            <error-message name="email" class="error-feedback" />
          </div>
          <div class="form-group">
            <label for="contacts">Контактная информация</label>
            <field name="contacts" type="text" class="form-control" />
            <error-message name="contacts" class="error-feedback" />
          </div>
          <div class="form-group">
            <label for="address">Адрес</label>
            <field name="address" type="text" class="form-control" />
            <error-message name="address" class="error-feedback" />
          </div>
          <div class="form-group">
            <label for="description">Дополнительная информация</label>
            <field name="description" type="text" class="form-control" />
            <error-message name="description" class="error-feedback" />
          </div>
          <!--
          <div class="form-group">
            <label for="password">Password</label>
            <Field name="password" type="password" class="form-control" />
            <ErrorMessage name="password" class="error-feedback" />
          </div>-->

          <div class="form-group">
            <button class="btn btn-primary btn-block" :disabled="loading">
              <span v-show="loading" class="spinner-border spinner-border-sm"></span>
              Зарегистрироваться
            </button>
          </div>
        </div>
      </form-component>

      <div v-if="message" class="alert" :class="successful ? 'alert-success' : 'alert-danger'">
        {{ message }}
      </div>
    </div>
  </div>
</template>

<script>
  import { Form as FormComponent, Field, ErrorMessage } from 'vee-validate'
  import * as yup from 'yup'

  export default {
    name: 'Register',
    components: {
      FormComponent,
      Field,
      ErrorMessage,
    },
    data() {
      const schema = yup.object().shape({
        name: yup.string().required('Введите название компании').max(500, 'Масимум 500 символов'),
        email: yup
          .string()
          .required('Введите адрес электронной почты')
          .email('Неверный формат электронной почты')
          .max(50, 'Масимум 50 символов'),
      })

      return {
        successful: false,
        loading: false,
        message: '',
        schema,
      }
    },
    computed: {
      loggedIn() {
        return this.$store.state.auth.status.loggedIn
      },
    },
    mounted() {
      if (this.loggedIn) {
        this.$router.push('/profile')
      }
    },
    methods: {
      handleRegister(user) {
        this.message = ''
        this.successful = false
        this.loading = true

        this.$store.dispatch('auth/register', user).then(
          (data) => {
            this.message = data.message
            this.successful = true
            this.loading = false
          },
          (error) => {
            this.message =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString()
            this.successful = false
            this.loading = false
          }
        )
      },
    },
  }
</script>

<style scoped>
  .form-group.required .control-label:after {
    content: ' *';
    color: red;
  }
</style>
