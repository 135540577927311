<template>
  <nav>
    <ul class="pagination justify-content-center m-0">
      <li class="page-item" v-bind:class="{ active: button.number === currentPage + 1 }" v-for="button in buttons">
        <button v-if="button.number" type="button" class="page-link" v-on:click="goToPage(button.number)">
          {{ button.number }}
        </button>
        <span v-if="button.isSpacer" class="page-link page-spacer">...</span>
      </li>
    </ul>
  </nav>
</template>

<script>
  function makeButtons(currentPage, pageCount) {
    const maxButtonCount = 9
    const buttons = []
    const middle = Math.ceil(maxButtonCount / 2)
    if (pageCount <= maxButtonCount) {
      // 1 2 3 4 5 6 7
      for (let i = 1; i <= pageCount; i++) {
        buttons.push({ number: i })
      }
    } else if (currentPage <= middle) {
      // 1 2 3 4 5 ... 8
      const to = maxButtonCount - 2
      for (let i = 1; i <= to; i++) {
        buttons.push({ number: i })
      }
      buttons.push({ isSpacer: true })
      buttons.push({ number: pageCount })
    } else if (currentPage >= pageCount - (maxButtonCount - middle)) {
      // 1 ... 4 5 6 7 8
      buttons.push({ number: 1 })
      buttons.push({ isSpacer: true })
      const from = pageCount - maxButtonCount + 3
      for (let i = from; i <= pageCount; i++) {
        buttons.push({ number: i })
      }
    } else {
      // 1 ... 4 5 6 ... 9
      buttons.push({ number: 1 })
      buttons.push({ isSpacer: true })
      const from = currentPage - middle + 3
      const to = from + (maxButtonCount - 4)
      for (let i = from; i <= to; i++) {
        buttons.push({ number: i })
      }
      buttons.push({ isSpacer: true })
      buttons.push({ number: pageCount })
    }
    return buttons
  }

  export default {
    name: 'Pagination',
    props: {
      currentPage: Number,
      pageCount: Number,
    },
    data() {
      return {
        buttons: [],
      }
    },
    watch: {
      currentPage: function (newValue /*, oldValue*/) {
        this.buttons = makeButtons(newValue, this.pageCount)
      },
      pageCount: function (newValue /*, oldValue*/) {
        this.buttons = makeButtons(this.currentPage, newValue)
      },
    },
    created() {
      this.buttons = makeButtons(this.currentPage, this.pageCount)
    },
    methods: {
      goToPage(pageNum) {
        this.$emit('change', pageNum - 1)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .page-item {
    margin: 0.3rem;
    display: inline-block;
    border-radius: 8px;
    overflow: hidden;

    .page-link {
      border: none;
    }
  }
</style>
