import Login from '../views/Login.vue'
import Profile from '../views/Profile.vue'
import Register from '../views/Register.vue'

export const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { isPublic: true, layout: 'AuthLayout' },
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: { isPublic: true },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
  },
]

export const registerGuards = (router) => {
  router.beforeEach((to) => {
    const loggedIn = localStorage.getItem('user')
    console.log(to.meta, '!!!!!!!--------------')
    if (to.meta.isPublic !== true && !loggedIn) {
      return { path: '/login', query: { returnUrl: to.fullPath } }
    }
  })
}
