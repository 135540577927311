<template>
  <div class="page-content">
    <page-header>
      <h2>Реестр событий</h2>
    </page-header>
    <section :class="{ content: true }">
      <template class="d-block">
        <div class="table-responsive pb-5">
          <table class="table-custom pb-5 overflow-auto table">
            <thead>
              <tr>
                <th class="col-table-3">Наименование</th>
                <th class="col-table-3 col-2">Дата</th>
                <th class="col-table-4">Сообщение</th>
                <th class="col-table-1">Информация</th>
                <th class="col-table-4">Код</th>
              </tr>
              <tr>
                <td>
                  <div class="d-flex w-100 justify-content-start">
                    <button class="btn py-0 px-2" v-on:click="resetDkFilter" title="Очистить">
                      <i class="fas fa-times" />
                    </button>
                    <Select2
                      placeholder="Фильтр по ДК"
                      :options="listComplexes"
                      v-model="request.intercomComplexId"
                      class="w-100 select2"
                      v-on:select="loadPage"
                    />
                    <input
                      type="text"
                      class="form-control ml-2"
                      v-model="historyLog"
                      placeholder="Срок хранения событий в логе"
                    />
                    <button-component @click="submitHistoryLog" class="primary ml-2">Применить</button-component>
                  </div>
                </td>
                <td>
                  <div class="d-flex">
                    <form class="bg-white" @submit.prevent>
                      <div>
                        <date-picker v-model="range" mode="dateTime" :masks="masks" is-range>
                          <template #default="{ inputValue, inputEvents, isDragging }">
                            <div class="d-flex w-100 justify-content-between items-center">
                              <div class="relative mr-2 flex-grow">
                                <input
                                  class="flex-grow pl-8 pr-2 py-1 bg-gray-100 border rounded w-full"
                                  :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                                  :value="inputValue.start"
                                  v-on="inputEvents.start"
                                />
                              </div>
                              <div class="relative flex-grow">
                                <input
                                  class="flex-grow pl-8 pr-2 py-1 bg-gray-100 border rounded w-full"
                                  :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                                  :value="inputValue.end"
                                  v-on="inputEvents.end"
                                />
                              </div>
                            </div>
                          </template>
                        </date-picker>
                      </div>
                    </form>
                    <button class="btn py-0 px-2" v-on:click="loadPage" title="Найти">
                      <i class="fas fa-search" />
                    </button>
                  </div>
                </td>
                <td>
                  <div class="d-flex justify-content-start">
                    <input type="text" class="form-control" v-model="request.search" @change="loadPage" />
                    <button class="btn py-0 px-2" v-on:click="loadPage" title="Найти">
                      <i class="fas fa-search" />
                    </button>
                    <button class="btn py-0 px-2" v-on:click="resetFlatFilter" title="Очистить">
                      <i class="fas fa-times" />
                    </button>
                  </div>
                </td>
                <td></td>
              </tr>
            </thead>
            <tbody v-if="page && page.data && page.data.length > 0">
              <tr v-for="ab in page.data" v-bind:key="ab.id">
                <td>{{ ab.deviceName }}</td>
                <td>{{ moment(ab.eventDate).format('DD.MM.YYYY HH:mm') }}</td>
                <td>{{ ab.message }}</td>
                <td>{{ ab.additionalInfo }}</td>
                <td>{{ ab.eventCode }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="7">
                  <empty-list-message />
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="page.totalPages > 1">
            <pagination :page-count="page.totalPages" :current-page="page.pageNumber" @change="onPageChange($event)" />
          </div>
        </div>
      </template>
    </section>
  </div>
</template>

<script>
  import API from '@/common/api/index'
  import Constants from '@/common/constants'
  import PageHeader from '@/common/components/page/PageHeader'
  import EmptyListMessage from '@/common/components/EmptyListMessage'
  import moment from 'moment'
  import 'v-calendar/dist/style.css'
  import { DatePicker } from 'v-calendar'
  import ButtonComponent from '@/common/ui/ButtonComponent'
  import Pagination from '@/common/components/Pagination'
  export default {
    name: 'EventRegister',
    components: {
      Pagination,
      ButtonComponent,
      DatePicker,
      EmptyListMessage,
      PageHeader,
    },
    data() {
      return {
        range: {
          start: null,
          end: null,
        },
        masks: {
          input: 'YYYY-MM-DD h:mm',
        },
        historyLog: null,
        moment: null,
        request: {
          OrderBy: 'DESC',
          SortField: 'deviceDate',
          pageNumber: 0,
          pageSize: Constants.pageSize,
          intercomComplexId: null,
          DateFrom: null,
          DateTo: null,
        },
        page: {
          data: [],
        },
        listComplexes: [],
        abEditId: -1,
      }
    },
    created() {
      API.getComplexList().then((res) => {
        console.log(res.data)
        this.listComplexes = res.data?.data?.map((item) => {
          return {
            text: item?.name,
            id: item?.id,
          }
        })
      })
      this.loadPage()
      this.moment = moment
    },
    methods: {
      submitHistoryLog() {
        API.saveHistoryLog({ intercomComplexId: this.request.intercomComplexId, daysCount: Number(this.historyLog) })
          .then(() => {
            Constants.alert.fire(`Сохранение срок хранения на ${Number(this.historyLog)} дня`, 'Успешно', 'success')
            this.loadPage()
            this.historyLog = null
          })
          .catch((error) => {
            Constants.alert.fire('Сохранение', error.response.data, 'error')
          })
      },
      onPageChange(pageNumber) {
        this.request.pageNumber = pageNumber
        this.loadPage()
      },
      resetDkFilter() {
        this.request.intercomComplexId = ''
        this.page.data = []
        this.loadPage()
      },
      loadPage() {
        ;(this.request.DateFrom = this.range.start && this.range.start),
          (this.request.DateTo = this.range.end && this.range.end),
          console.log(this.range.start)
        API.getEventRegistryList(this.request).then((listResp) => {
          console.log(listResp.data, 'data')
          this.page = {}
          this.$nextTick(() => {
            this.page = listResp.data
          })
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '/src/assets/theme/default/colors.scss';
  @import '/src/common/index';
  .table-responsive {
    border-radius: 10px;
  }
  .page-content {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }
  .content {
    padding: 10px 12px;
    position: relative;
    overflow-y: visible;

    .filter {
      position: absolute;
      width: 350px;
      height: auto;
      top: 0;
      right: 0;
      overflow: hidden;
      background-color: transparent;
      opacity: 1;
      transition: opacity 0.2s;
      z-index: 1000;
    }

    .filter-enter, .filter-leave-to /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0;
    }

    .table-custom {
      border-radius: 10px;
      background-color: $white;
      overflow: hidden;
      min-width: 100%;

      tr {
        td {
          padding: 10px 10px;
          border: 1px solid $gray-light1;

          &.table-action-cell {
            white-space: nowrap;
          }
        }

        th {
          padding: 20px 16px;
          border: 1px solid $gray-light1;
          background-color: change-color($blue-light, $alpha: 0.2);
        }
      }
    }
  }

  .map-filter-component {
    padding: 14px 32px;
  }

  .select2::v-deep {
    .v3dp__popout {
      z-index: 999999 !important;
    }
    .select2-container {
      z-index: 11;
      width: 100% !important;
      max-width: 100% !important;
    }

    .select2-container--default .select2-selection--single {
      height: calc(2.25rem + 2px) !important;
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow {
      top: 7px !important;
    }
  }
</style>
