<template>
  <div class="wrapper">
    <header-component />
    <sidebar @collapse-toggle="sidebarToggle" />
    <div class="content-wrapper">
      <section class="content-data">
        <slot />
      </section>
    </div>
    <footer-component />
  </div>
</template>

<script>
  import HeaderComponent from '@/common/components/HeaderComponent.vue'
  import Sidebar from '@/common/components/Sidebar.vue'
  import FooterComponent from '@/common/components/FooterComponent.vue'

  export default {
    name: 'DefaultLayout',
    components: {
      HeaderComponent,
      Sidebar,
      FooterComponent,
    },
    data: () => ({
      isCollapsed: false,
    }),
    mounted() {
      this.isCollapsed = document.querySelector('body').classList.contains('sidebar-collapse')
    },
    created() {
      $('body').addClass('hold-transition sidebar-mini layout-fixed')
    },
    methods: {
      sidebarToggle() {
        this.isCollapsed = !this.isCollapsed
        const bodyClasses = document.querySelector('body').classList
        this.isCollapsed ? bodyClasses.remove('sidebar-collapse') : bodyClasses.add('sidebar-collapse')
      },
    },
  }
</script>

<style scoped lang="scss">
  .content-wrapper {
    display: flex;

    .content-data {
      width: 100%;
    }
  }
</style>
