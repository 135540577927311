import axios from 'axios'

export default {
  signin: function (data) {
    return axios.post('/api/v1/auth/login-by-pass', data)
  },
  signup: function (data) {
    return axios.post('/api/company/register', data)
  },
}
