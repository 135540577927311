<template>
  <div>
    <input
      type="checkbox"
      class="custom-checkbox"
      :id="value"
      :name="value"
      :checked="checked"
      :value="value"
      :disabled="disabled"
      @change="$emit('change', checked)"
    />
    <label :for="value"></label>
  </div>
</template>

<script>
  export default {
    name: 'CheckboxComponent',
    props: {
      value: {
        type: Boolean,
      },
      checked: {
        type: Boolean,
        default: () => false,
      },
      disabled: {
        type: Boolean,
        default: () => false,
      },
    },
    data() {
      return {
        model: false,
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import '/src/assets/theme/default/colors.scss';

  .custom-checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  .custom-checkbox + label {
    display: inline-flex;
    align-items: center;
    user-select: none;
  }

  .custom-checkbox + label::before {
    content: '';
    display: inline-block;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid $main-light;
    border-radius: 4px;
    margin-right: 8px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
  }

  .custom-checkbox:checked + label::before {
    border-color: $main;
    background-color: $main;
    background-image: url('../../assets/svg/checkbox.svg');
    background-position: center;
    background-size: 70%;
  }

  .custom-checkbox:not(:disabled):not(:checked) + label:hover::before {
    border-color: $main-light;
  }

  .custom-checkbox:not(:disabled):active + label::before {
    background-color: $main-light;
    border-color: $main-light;
  }

  .custom-checkbox:disabled + label::before {
  }
</style>
