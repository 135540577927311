<template>
  <div class="page-content">
    <page-header><h2>Rtsp-сервера</h2></page-header>
    <section :class="{ content: true }">
      <template v-if="items">
        <div v-if="items.length" class="table-responsive">
          <table class="table-custom table">
            <thead>
              <tr>
                <th class="col-table-1">Наименование</th>
                <th class="col-table-1">Описание</th>
                <th class="col-table-1">Url</th>
                <th class="col-table-1">Логин</th>
                <th class="col-table-1">Пароль</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in items" :key="item.id">
                <td>{{ item.name }}</td>
                <td>{{ item.description }}</td>
                <td>{{ item.apiUrl }}</td>
                <td>{{ item.login }}</td>
                <td>{{ item.password }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else>
          <empty-list-message />
        </div>
      </template>
    </section>
  </div>
</template>

<script>
  import API from '@/common/api'
  import PageHeader from '@/common/components/page/PageHeader'

  export default {
    name: 'Rtsp',
    components: {
      PageHeader,
    },
    data() {
      return {
        inProcess: false,
        items: [],
      }
    },
    mounted() {
      this.loadPage()
    },
    methods: {
      loadPage() {
        API.getRtspList()
          .then((resp) => {
            this.items = resp.data
          })
          .catch((e) => {
            console.log(e)
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '/src/assets/theme/default/colors.scss';
  @import '/src/common/index';

  .page-content {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }
  .content {
    padding: 10px 12px;
    position: relative;
    overflow-y: visible;

    .filter {
      position: absolute;
      width: 350px;
      height: auto;
      top: 0;
      right: 0;
      overflow: hidden;
      background-color: transparent;
      opacity: 1;
      transition: opacity 0.2s;
      z-index: 1000;
    }

    .filter-enter, .filter-leave-to /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0;
    }
  }
</style>
