<template>
  <div class="icon" v-if="component" v-html="component" />
</template>

<script>
  export default {
    name: 'IconComponent',
    props: {
      name: {
        type: String,
        required: true,
      },
    },
    data: () => ({
      component: null,
    }),
    async mounted() {
      let component
      try {
        component = require(`/src/assets/svg/${this.name}.svg`)
      } catch (e) {
        console.log(e)
      }
      fetch(component)
        .then((body) => body.text())
        .then((data) => (this.component = data))
    },
  }
</script>

<style scoped>
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
