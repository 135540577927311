<template>
  <section class="dashboard">
    <header-component class="dashboard-header" @collapse-toggle="sidebarToggle" />
    <sidebar
      :is-collapsed="!getMenuState"
      :class="[
        'dashboard-sidebar',
        {
          'dashboard-sidebar_collapsed': !getMenuState,
        },
      ]"
    />
    <router-view class="dashboard-page" :key="$route.path" />
    <footer-component class="dashboard-footer" />
  </section>
</template>

<script>
  import Sidebar from '@/common/components/Sidebar.vue'
  import HeaderComponent from '@/common/components/HeaderComponent'
  import FooterComponent from '@/common/components/FooterComponent'
  import { mapGetters } from 'vuex'

  export default {
    name: 'DashboardLayout',
    components: { FooterComponent, HeaderComponent, Sidebar },
    computed: {
      ...mapGetters({
        getMenuState: 'getMenuState',
      }),
    },
    methods: {
      sidebarToggle() {
        this.$store.dispatch('toggleMenuState')
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '/src/assets/theme/default/colors.scss';

  .dashboard {
    height: 100vh;
    display: grid;
    transition: all 0.3s ease;
    grid-template:
      'header header' minmax(0px, 75px)
      'sidebar content' auto
      'footer footer' 60px;
    grid-template-columns: min-content auto;

    &-page {
      grid-area: content;
      overflow: auto;
      background-color: $white-mist-bg;
      background-repeat: no-repeat;
      background-position: bottom right;
    }

    &-header {
      grid-area: header;
      z-index: 2;
    }

    &-footer {
      grid-area: footer;
    }

    &-sidebar {
      grid-area: sidebar;
      z-index: 3;
      background: $blue-dark;
      overflow: hidden;
      width: 284px;
      will-change: width;
      transition: 0.3s width ease;
      padding: 12px;

      &_collapsed {
        border: none;
        width: 76px;
        background-color: $blue-dark;
      }
    }
  }
</style>
