<template>
  <footer class="main-footer ml-0">
    <div class="float-right d-none d-sm-block">Версия 1.0.0</div>
    &copy; {{ new Date().getFullYear() }}
  </footer>
</template>

<script>
  export default {
    name: 'FooterComponent',
  }
</script>
