import API from '../auth/api/index'

class AuthService {
  async login(user) {
    const response = await API.signin({
      login: user.username,
      password: user.password,
    })
    if (response.data.accessToken) {
      await localStorage.setItem('user', JSON.stringify(response.data))
      await localStorage.setItem('token', response.data.accessToken)
    }
    return response.data
  }

  logout() {
    localStorage.removeItem('user')
    localStorage.removeItem('token')
    localStorage.removeItem('orgName')
  }

  register(user) {
    console.log(user)
    return API.signup({
      name: user.name,
      email: user.email,
      address: user.address,
      contacts: [user.contacts],
      description: user.description,
    })
  }
}

export default new AuthService()
