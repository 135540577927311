<template>
  <table class="table-bordered w-100 table-light">
    <thead v-if="$slots.th">
      <tr>
        <slot name="th" />
      </tr>
    </thead>
    <tbody>
      <tr v-if="$slots.filters">
        <slot name="filters" />
      </tr>
      <template v-if="content?.rows?.length">
        <template v-for="(item, index) in content.rows" :key="index">
          <component v-if="content.component" :is="content.component" :content="item" />
          <slot v-else-if="$slots.row" name="row" :item="item" />
          <template v-if="$slots.row && item.showChildren && item.childParameters.rows.length">
            <slot v-for="row in item.childParameters.rows" :key="row.id" name="row" :item="row" />
          </template>
        </template>
      </template>
      <template v-else>
        <tr><td>No Items</td></tr>
      </template>
    </tbody>
  </table>
</template>

<script>
  export default {
    name: 'TreeTable',
    props: {
      content: {
        type: Object,
        default: () => {},
      },
    },
  }
</script>

<style scoped></style>
