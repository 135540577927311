<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">Загрузить сертификат</span>
            </div>
            <div class="custom-file">
              <input
                type="file"
                class="custom-file-input"
                id="inputGroupFile01"
                accept="text"
                @change="onChangeFile($event)"
              />
              <label class="custom-file-label" for="inputGroupFile01">Выберите файл</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import api from '../../../common/api'
  import Constants from '../../../common/constants'

  export default {
    name: 'LoadLicenseFile',
    props: {
      onClose: Function,
    },
    data() {
      return {}
    },
    methods: {
      onChangeFile(event) {
        api.loadLicense(event.target.files[0]).catch((error) => {
          Constants.alert.fire('Загрузка файла лицензии', error.response.data, 'error')
        })
        this.onClose()
      },
      onCancel() {
        this.onClose()
      },
    },
  }
</script>
