import { createRouter, createWebHistory } from 'vue-router'
import { routes as authRoutes, registerGuards as registerAuthGuards } from '@/modules/auth/router/index'
import { routes as organizationRoutes } from '@/modules/organization/router/index'
import { routes as complexRoutes } from '@/modules/complex/router/index'
import { routes as deviceRoutes } from '@/modules/devices/router/index'
import { routes as EventRegister } from '@/modules/EventRegistry/router/index'
import { routes as settingsRouters } from '@/modules/settings/router/index'
import { routes as rtspRouters } from '@/modules/rtsp/router/index'
import Content from '@/views/ContentView'
import NotFound from '@/views/NotFound'
import About from '@/views/About'

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: { name: 'Login' },
    meta: { isPublic: true },
  },
  {
    path: '/about',
    name: 'About',
    meta: { isPublic: true },
    component: About,
  },
  ...authRoutes,
  ...organizationRoutes,
  ...settingsRouters,
  ...complexRoutes,
  ...deviceRoutes,
  ...EventRegister,
  ...rtspRouters,
  {
    path: '/content/:name',
    name: 'Content',
    component: Content,
  },
  {
    path: '/:catchAll(.*)',
    component: NotFound,
    meta: { isPublic: true, layout: 'GuestLayout' },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

registerAuthGuards(router)

export default router
