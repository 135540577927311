//import API from '../modules/admin/api/nsi'
import axios from 'axios'

export default {
  // loadSelectOptions(dictName, optionsArray, requestData, isClear) {
  //   API.getNsi(dictName, requestData).then(function (response) {
  //     if (isClear) {
  //       while (optionsArray.length > 1) optionsArray.pop()
  //     }
  //
  //     if (response.data && response.data.length) {
  //       response.data.forEach((i) => optionsArray.push(i))
  //     }
  //   })
  // },
  downloadFile(url, filename) {
    return axios.get(url, { responseType: 'blob' }).then((response) => {
      const blob = new Blob([response.data], { type: response.data.type })
      const link = document.createElement('a')
      const contentDisposition = response.headers['content-disposition']
      filename = filename || 'download'
      if (contentDisposition) {
        const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-\\.]+)(?:; ?|$)/i
        filename = decodeURIComponent(utf8FilenameRegex.exec(contentDisposition)[1])
      }
      link.href = URL.createObjectURL(blob)
      link.download = filename
      link.click()
      URL.revokeObjectURL(link.href)
    })
  },
}
