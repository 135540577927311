<template>
  <div class="modal show" role="dialog" @click.self="backClick">
    <div :class="'modal-dialog ' + sizeClass" role="document">
      <div class="modal-content">
        <div class="modal-header" v-if="title">
          <h5 class="modal-title">{{ title }}</h5>
          <button type="button" class="close" @click="$emit('close')" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <slot />
        </div>
        <div class="modal-footer" v-if="$slots.actions">
          <slot name="actions" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ModalComponent',
    props: {
      title: {
        type: String,
        required: false,
        default: '',
      },
      sizeClass: {
        type: String,
        required: false,
        default: 'modal-xl',
      },
      autoClose: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    methods: {
      backClick() {
        if (this.autoClose) {
          this.$emit('close')
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .modal {
    display: flex;
    height: 100vh;
    position: fixed;
    width: 100vw;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.25);
    &-content {
      border-radius: 12px;
      border: none;
      box-shadow: none;
      background-color: #fff;
    }
    .modal-dialog {
      margin: 40px auto;
      width: 100%;
      // max-width: 80%;
    }
  }

  .select2-drop {
    z-index: 10050 !important;
  }

  .select2-search-choice-close {
    margin-top: 0 !important;
    right: 2px !important;
    min-height: 10px;
  }

  .select2-search-choice-close:before {
    color: black !important;
  }
  /*Prevent select2 from automatically losing focus*/
  .select2-container {
    z-index: 16000 !important;
  }

  .select2-drop-mask {
    z-index: 15990 !important;
  }

  .select2-drop-active {
    z-index: 15995 !important;
  }
</style>
