<template>
  <aside>
    <!-- Sidebar -->
    <div :class="['sidebar', { sidebar_collapsed: isCollapsed }]">
      <!-- Sidebar Menu -->
      <tree-view :is-collapsed="isCollapsed" :nodes="getMenuNodes()" />
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
  import TreeView from '../ui/TreeView'
  import refreshAuthorizationHeader from '../axios'
  import jwtDecode from 'jwt-decode'
  import Constants from '@/common/constants'

  export default {
    name: 'Sidebar',
    components: { TreeView },
    props: {
      isCollapsed: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      rmisDicts: [],
      systemDicts: [],
      menuAdmin: [
        { title: 'Организации', to: '/organizations', icon: 'building', class: 'fas fa-briefcase' },
        { title: 'Домофонные комплексы', to: '/complexes', icon: 'building', class: 'fas fa-city' },
        { title: 'Реестр событий', to: '/event-register', class: 'fas fa-cash-register' },
        { title: 'Настройки', to: '/settings', class: 'fas fa-cog' },
        { title: 'Rtsp-прокси для камер', to: '/rtsp', class: 'fas fa-camera' },
      ],
      menuOrg: [
        { title: 'Домофонные комплексы', to: '/complexes', class: 'fas fa-city' },
        { title: 'Реестр событий', to: '/event-register', class: 'fas fa-cash-register' },
      ],
    }),
    computed: {
      getMenuState() {
        return this.$store.getters.getMenuState
      },
    },
    mounted() {
      refreshAuthorizationHeader()
      this.loadDictionaries()
    },
    methods: {
      loadDictionaries() {},
      getMenuNodes() {
        if (this.$store.state.auth.user) {
          const data = jwtDecode(this.$store.state.auth.user.accessToken)
          if (data[Constants.authKey].includes('ConfigServerAdmin')) return this.menuAdmin
          if (data[Constants.authKey].includes('OrganizationInnerAdmin')) return this.menuOrg
        }
        return []
      },
      toggles() {
        this.$emit('collapse-toggle')
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import 'src/assets/theme/default/colors';
  .sidebar {
    overflow-x: hidden;
    padding: 0;
  }
  .dashboard-sidebar {
    overflow-y: auto !important;
  }
</style>
