<template>
  <section class="dashboard">
    <div class="dashboard-page">
      <router-view :key="$route.path" />
    </div>
    <footer-component class="dashboard-footer" />
  </section>
</template>

<script>
  import FooterComponent from '@/common/components/FooterComponent'

  export default {
    name: 'AuthLayout',
    components: { FooterComponent },
    data: () => ({
      isDashboardCollapsed: false,
    }),
  }
</script>

<style lang="scss" scoped>
  @import '/src/assets/theme/default/colors.scss';

  .dashboard {
    height: 100vh;
    display: grid;
    transition: all 0.3s ease;
    grid-template:
      'content content' auto
      'footer footer' 60px;
    grid-template-columns: min-content auto;

    &-page {
      grid-area: content;
      overflow: auto;
      background-color: $main;
      background-repeat: no-repeat;
      background-position: bottom right;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
      height: 100%;
    }

    &-footer {
      grid-area: footer;
    }
  }
</style>
