<template>
  <div>
    <div class="modal-header">Редактирование параметра</div>
    <div class="modal-body">
      <div class="form-group" v-for="field in formItems" :key="field.id">
        <template v-if="field.label">
          <label :for="field.id">
            <span class="d-block">{{ field.label }}</span>
            <component
              v-model="tmpField[field.id]"
              @change="logger($event, field.id)"
              :is="field.field"
              v-bind="clearField(field)"
            >
              <template v-if="field.options">
                <option v-for="opt in field.options" :key="opt" :value="opt">{{ opt }}</option>
              </template>
            </component>
          </label>
        </template>
      </div>
    </div>
    <div class="modal-footer">
      <button-component @click="closeModal" kind="secondary">Отменить</button-component>
      <button-component @click="saveField">Сохранить</button-component>
    </div>
  </div>
</template>

<script>
  import Constants from '../../../common/constants'
  import ButtonComponent from '@/common/ui/ButtonComponent'
  import { mapParameterFieldToForm } from '@/common/constants'
  import { required } from '@vuelidate/validators'
  import { useVuelidate } from '@vuelidate/core'
  export default {
    name: 'EditParameterForm',
    components: { ButtonComponent },
    props: {
      item: {
        type: Object,
        required: false,
        default: () => {},
      },
    },
    data: () => ({
      tmpField: {},
    }),
    validations() {
      return {
        tmpField: {
          name: { required },
          description: { required },
        },
      }
    },
    computed: {
      formItems() {
        return Object.entries(this.item).map((o) => {
          return { ...mapParameterFieldToForm[o[0]], id: o[0], value: o[1] }
        })
      },
    },
    mounted() {
      this.tmpField = { ...this.item }
    },
    setup: () => ({ v$: useVuelidate() }),
    methods: {
      logger($e, id) {
        if (mapParameterFieldToForm[id].type === 'checkbox') {
          this.tmpField[id] = $e.target.checked
          return
        }
        this.tmpField[id] = $e.target.value
      },
      closeModal() {
        this.$emit('close-modal', this.tmpField)
      },
      clearField(field) {
        const props = {
          placeholder: field.placeholder,
          name: field.name,
          disabled: field.disabled,
          value: this.tmpField[field.id],
        }
        if (field.type) {
          props.type = field.type
        }
        return props
      },
      saveField() {
        this.v$.$touch()
        if (this.v$.$invalid) {
          Constants.alert.fire('Ошибка', 'Поля "Название" и "Описание" обязательны', 'error')
          return
        }
        this.$emit('update-field', this.tmpField)
        this.$emit('close-modal')
      },
    },
  }
</script>

<style scoped></style>
