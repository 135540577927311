<template>
  <div class="page-content">
    <page-header>
      <h2>Домофонные комплексы</h2>
      <template #actions>
        <div class="d-flex actions">
          <button-component kind="tertiary" @click="modals.loadLicenseOnline = !modals.loadLicenseOnline">
            Добавить ДК (онлайн)
          </button-component>
          <button-component kind="tertiary" @click="modals.loadLicenseRequest = !modals.loadLicenseRequest">
            Сформировать запрос на лицензию
          </button-component>
          <button-component kind="tertiary" @click="modals.loadLicenseFile = !modals.loadLicenseFile">
            Загрузить лицензию
          </button-component>
          <button-component kind="tertiary" @click="sync">Синхронизация с ЦС</button-component>
          <button-component kind="tertiary" @click="modals.filter = !modals.filter">
            <template #prepend>
              <icon-component class="mr-2" name="filter" />
            </template>
            Фильтр
          </button-component>
        </div>
      </template>
    </page-header>
    <section :class="{ content: true }">
      <template v-if="page.data">
        <div v-if="page.data.length" class="table-responsive">
          <table class="table-custom table">
            <thead>
              <tr>
                <th class="col-table-2">Наименование</th>
                <th class="col-table-1">Ключ лицензии</th>
                <th class="col-table-1">Поддержка КС</th>
                <th>Действия</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in page.data" :key="item.id">
                <td>{{ item.name }}</td>
                <td>
                  {{ item.licenseKey }}
                </td>
                <td>
                  {{ item.supportExecuteCommands ? 'Да' : 'Нет' }}
                </td>
                <td class="table-action-cell">
                  <a class="btn" title="Устройства" @click="showDevices(item.id)">
                    <i class="fas fa-pen"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="page.totalPages > 1">
            <Pagination :page-count="page.totalPages" :current-page="page.pageNumber" @change="onPageChange($event)" />
          </div>
        </div>
        <div v-else>
          <empty-list-message />
        </div>
      </template>

      <section class="modals">
        <transition name="filter">
          <div class="filter" v-if="modals.filter">
            <complex-filters
              v-bind:filters="filters"
              v-on:apply="onFiltersApply($event)"
              v-on:reset="onFiltersReset($event)"
            />
          </div>
        </transition>
        <transition name="loadLicenseOnline">
          <div class="filter" v-if="modals.loadLicenseOnline">
            <write-license
              v-on:cancelLoadLicense="cancelLoadLicenseOnline($event)"
              v-on:onSuccessLoadLicenseOnline="onSuccessLoadLicenseOnline($event)"
            ></write-license>
          </div>
        </transition>
        <transition name="loadLicenseRequest">
          <div class="filter" v-if="modals.loadLicenseRequest">
            <write-license
              v-bind:online="false"
              v-on:cancelLoadLicense="onCancelLoadLicenseRequest($event)"
              v-on:onSuccessLoadLicense="onSuccessLoadLicenseRequest($event)"
            ></write-license>
          </div>
        </transition>
      </section>
      <section v-if="modals.loadLicenseFile" class="modals">
        <modal-component title="Загрузка лицензии" class="modal-content--card" @close="modals.loadLicenseFile = false">
          <load-license-file :onClose="onCloseLoadLicenseFile" />
        </modal-component>
      </section>
    </section>
  </div>
</template>

<script>
  import API from '@/common/api/index'
  import ComplexFilters from '../components/ComplexFilter.vue'
  import WriteLicense from '../components/WriteLicense.vue'
  import Constants from '../../../common/constants'
  import ButtonComponent from '@/common/ui/ButtonComponent'
  import PageHeader from '@/common/components/page/PageHeader'
  import IconComponent from '@/common/ui/IconComponent'
  import EmptyListMessage from '../../../common/components/EmptyListMessage'
  import LoadLicenseFile from '../components/LoadLicenseFile'
  import ModalComponent from '@/common/ui/ModalComponent'
  import jwtDecode from 'jwt-decode'

  export default {
    name: 'Complexes',
    components: {
      EmptyListMessage,
      IconComponent,
      PageHeader,
      ButtonComponent,
      ComplexFilters,
      WriteLicense,
      LoadLicenseFile,
      ModalComponent,
    },
    data() {
      return {
        request: {
          pageNumber: 0,
          pageSize: Constants.pageSize,
        },
        filters: {
          search: '',
          companyId: null,
        },
        page: {
          data: [],
        },
        modals: {
          filter: false,
          loadLicenseOnline: false,
          loadLicenseRequest: false,
          loadLicenseFile: false,
        },
        adminMode: false,
      }
    },
    mounted() {
      this.loadPage()
    },
    methods: {
      onPageChange(pageNumber) {
        this.request.pageNumber = pageNumber
        this.loadPage()
      },
      loadPage() {
        debugger
        //if (this.$store.state.auth.user) {
        const request = {
          ...this.request,
          ...this.filters,
        }
        API.getComplexList(request).then((response) => {
          this.page = {}
          this.$nextTick(() => {
            this.page = response.data
          })
        })
        if (this.$store.state.auth.user) {
          const data = jwtDecode(this.$store.state.auth.user.accessToken)
          this.adminMode == data[Constants.authKey].includes('ConfigServerAdmin')
        }
        //}
      },
      showDevices(id) {
        console.log(id)
        this.$router.push({ name: 'cardComplex', params: { id: id, action: 'edit' } })
      },
      onFiltersApply(filters) {
        this.filters = filters
        this.request.pageNumber = 0
        this.loadPage()
      },
      onFiltersReset(filters) {
        this.filters = filters || {}
        this.request.pageNumber = 0
        this.loadPage()
      },
      onSuccessLoadLicenseOnline() {
        this.modals.loadLicenseOnline = false
        this.loadPage()
      },
      cancelLoadLicenseOnline() {
        this.modals.loadLicenseOnline = false
      },
      onSuccessLoadLicenseRequest() {
        this.modals.loadLicenseRequest = false
      },
      onCancelLoadLicenseRequest() {
        this.modals.loadLicenseRequest = false
      },
      onCloseLoadLicenseFile() {
        this.modals.loadLicenseFile = false
        this.loadPage()
      },
      sync() {
        API.syncDeviceTypes()
          .then(
            API.syncComplexes()
              .then(() => {
                Constants.alert.fire('Успешно', '', 'success')
              })
              .catch((err) => {
                Constants.alert.fire('Ошибка загрузки ДК', err.message, 'error')
              })
          )
          .catch((err) => {
            Constants.alert.fire('Ошибка загрузки типов устройств', err.message, 'error')
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '/src/assets/theme/default/colors.scss';

  .page-content {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }
  .content {
    padding: 10px 12px;
    position: relative;
    overflow-y: visible;

    .filter {
      position: absolute;
      width: 350px;
      height: auto;
      top: 0;
      right: 0;
      overflow: hidden;
      background-color: transparent;
      opacity: 1;
      transition: opacity 0.2s;
      z-index: 1000;
    }

    .filter-enter, .filter-leave-to /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0;
    }

    .table-custom {
      border-radius: 10px;
      background-color: $white;
      overflow: hidden;
      min-width: 100%;

      tr {
        td {
          padding: 10px 10px;
          border: 1px solid $gray-light1;

          &.table-action-cell {
            white-space: nowrap;
          }
        }

        th {
          padding: 20px 16px;
          border: 1px solid $gray-light1;
          background-color: change-color($blue-light, $alpha: 0.2);
        }
      }
    }
  }

  .map-filter-component {
    padding: 14px 32px;
  }
</style>
