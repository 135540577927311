<template>
  <div class="page-content">
    <page-header>
      <template #breadcrumbs>
        <router-link to="/complexes">Домовые комплексы</router-link>
        &raquo;
      </template>

      <h2
        style="
           {
            margin-right: 500px;
          }
        "
      >
        Домовой комплекс {{ complex?.name }}
      </h2>
    </page-header>
    <div class="card" v-if="complex">
      <div class="col-md-12">
        <div class="card-header">
          <h4>Ключи безопасности</h4>
        </div>
        <div class="card-body">
          <div class="row mt-10">
            <div class="col-md-4">
              <div class="form-group">
                <label for="server" class="control-label">Режим работы с ключами</label>
                <Select2 :options="keyModes" v-model="complex.secureKeyMode" class="w-100 select2" />
              </div>
            </div>
          </div>
          <div class="row mt-10">
            <div class="col-md-4">
              <div class="form-group">
                <label for="server" class="control-label">Ключ владельца</label>
                <input v-model="complex.secureOwnerKey" type="text" class="form-control" />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="server" class="control-label">Ключ администратора</label>
                <input v-model="complex.secureAdminKey" type="text" class="form-control" />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="server" class="control-label">Ключ оператора</label>
                <input v-model="complex.secureOperatorKey" type="text" class="form-control" />
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="float-right d-flex">
            <button-component class="mr-2" :class="{ disabled: inProcess }" @click="saveComplex">
              Сохранить
            </button-component>
          </div>
        </div>
      </div>
    </div>
    <div class="card" v-if="complex">
      <div class="card-header">
        <h4>Устройства</h4>
      </div>
      <template v-if="complex">
        <div class="d-flex my-2">
          <button-component class="mx-2" @click="addDevice()">Добавить</button-component>
          <button-component @click="saveAllowUpdateForDevices()">Обновить все устройства</button-component>
        </div>
      </template>
      <div class="card-inner" v-if="complex">
        <section :class="{ content: true }">
          <div>
            <template v-if="devices">
              <div v-if="devices.length" class="table-responsive">
                <table class="table-custom table">
                  <thead>
                    <tr>
                      <th>Название</th>
                      <th>Описание</th>
                      <th>Тип устройства</th>
                      <th>Серийный номер</th>
                      <th>Версия настроек</th>
                      <!-- <th>Ревизия</th>
                      <th>Номер ревизии</th> -->
                      <th>Обновление разрешено</th>
                      <th>Действия</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(device, index) in devices" :key="index">
                      <td>{{ device.name }}</td>
                      <td>{{ device.description }}</td>
                      <td>{{ device.deviceTypeName }}</td>
                      <td>{{ device.serialNumber }}</td>
                      <td>{{ device.versionNumber }}</td>
                      <!-- <td>{{ device.revisionNumber }}</td>
                      <td>{{ device.revisionVersionNumber }}</td> -->
                      <td class="td-center text-center">
                        <checkbox-component disabled :checked="device.allowUpdate" />
                      </td>
                      <!-- <td class="text-center">
                        <a class="btn" @click="openDoor(device.id)" title="Открыть дверь">
                          <i class="fas fa-solid fa-door-closed text-gray-dark"></i>
                        </a>
                      </td> -->
                      <td class="table-action-cell">
                        <a title="Редактирование настроек" class="btn" @click="editDevice(device.id)">
                          <i class="fas fa-pen"></i>
                        </a>
                        <a
                          title="Выпустить ssl-сертификат"
                          v-if="!device?.certificateId"
                          class="btn"
                          v-on:click="issuanceCertificate(device.id)"
                        >
                          <i class="fas fa-light text-success fa-file-contract"></i>
                        </a>
                        <a
                          title="Скачать ssl-сертификат"
                          v-if="device?.certificateId"
                          class="btn"
                          v-on:click="exportCeritficate(device.id)"
                        >
                          <i class="fas fa-download text-success"></i>
                        </a>

                        <a title="Удалить" class="btn" v-on:click="deleteDevice(device.id)">
                          <i class="fas fa-trash-alt text-danger" />
                        </a>
                        <a title="Обновить" class="btn" v-if="device.updateAvailable" v-on:click="changeUpdate(device)">
                          <i class="fas fa-sync" />
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else>
                <empty-list-message />
              </div>
            </template>
          </div>
        </section>
      </div>
    </div>
    <section v-if="editDeviceModal" class="modals">
      <modal-component class="modal-content--card" @close="editDeviceModal = false">
        <device-card v-bind:id="editedDevice" :intercomComplexId="id" :onClose="closeEditDeviceModal" />
      </modal-component>
    </section>
  </div>
</template>

<script>
  // import Constants from '@/common/constants'
  import API from '@/common/api/index'
  import PageHeader from '@/common/components/page/PageHeader'
  import { useVuelidate } from '@vuelidate/core'
  import disableAll from '@/common/directives/disable-all'
  import ButtonComponent from '@/common/ui/ButtonComponent'
  import DeviceCard from '../components/DeviceCard'
  import ModalComponent from '@/common/ui/ModalComponent'
  import Constants from '@/common/constants'
  import CheckboxComponent from '@/common/ui/CheckboxComponent'
  import moment from 'moment'

  export default {
    name: 'ComplexCard',
    components: {
      PageHeader,
      DeviceCard,
      ModalComponent,
      ButtonComponent,
      CheckboxComponent,
    },
    directives: {
      'disable-all': disableAll,
    },
    props: {
      type: String,
      id: {
        type: String,
        required: false,
      },
    },
    data() {
      return {
        backUrl: '/complexes',
        complex: null,
        devices: [],
        editedDevice: null,
        editDeviceModal: false,
        activeItem: 'tab1',
        housesList: [],
        houseOptions: [],
        selectedHouse: null,
        flatsList: [],
        valueKey: '',
        flatKey: '',
        editId: null,
        keyModes: [
          { text: 'Не определено', id: 'None' },
          { text: 'Ключи EMF', id: 'EMF' },
          { text: 'Пустые ключи EMF', id: 'EmptyEMF' },
          { text: 'MiFare', id: 'MiFare' },
        ],
      }
    },
    watch: {
      selectedHouse(val) {
        if (val) {
          API.getHouse(val).then((res) => {
            this.housesList = [res.data].map((item) => {
              return {
                id: item.id,
                text: item.address,
                ...item,
              }
            })
          })
        }
      },
    },
    created() {
      this.loadPage()
    },
    methods: {
      editKeys(flatKey) {
        this.valueKey = flatKey
        this.editId = flatKey
      },
      deleteKey(flat, idx) {
        flat.flatSerialKeys.splice(idx, 1)
        let req = {
          number: flat.number,
          houseId: flat.houseId,
          flatSerialKeys: flat.flatSerialKeys,
        }
        API.createFlat(req)
          .then(() => {
            Constants.alert.fire('Удаление', 'Успешно', 'success')
            this.valueKey = ''
            this.editId = null
            this.loadPage()
          })
          .catch((e) => {
            Constants.alert.fire('Ошибка', e, 'error')
          })
      },
      saveKeys(flat, flatKey, idx) {
        console.log(flat)
        if (this.valueKey.length) {
          let req = {}
          if (this.editId === flatKey) {
            flat.flatSerialKeys[idx] = this.valueKey
            req = {
              number: flat.number,
              houseId: flat.houseId,
              flatSerialKeys: flat.flatSerialKeys,
            }
          }
          API.createFlat(req)
            .then(() => {
              Constants.alert.fire('Обновление', 'Успешно', 'success')
              this.valueKey = ''
              this.editId = null
              this.loadPage()
            })
            .catch((e) => {
              Constants.alert.fire('Ошибка', e, 'error')
            })
        } else {
          Constants.alert.fire('Ошибка', 'Заполните поле', 'error')
        }
      },
      addKey(item) {
        this.editId = ''
        item.flatSerialKeys.unshift('')
      },
      saveFlat(house, flat) {
        if (this.flatKey.length) {
          let req = {}
          if (this.editId === flat) {
            req = {
              number: this.flatKey,
              houseId: house.id,
              flatSerialKeys: [],
            }
          }
          API.createFlat(req)
            .then(() => {
              Constants.alert.fire('Обновление', 'Успешно', 'success')
              this.valueKey = ''
              this.editId = null
              this.loadPage()
            })
            .catch((e) => {
              Constants.alert.fire('Ошибка', e, 'error')
            })
        } else {
          Constants.alert.fire('Ошибка', 'Заполните поле', 'error')
        }
      },
      addFlat(item) {
        this.editId = ''
        item.flats.unshift('')
      },
      cancelEdit(item) {
        this.editId = null
        item.flatSerialKeys = item.flatSerialKeys.filter((item) => item.length)
      },
      cancelFlat(house) {
        console.log(house, 'ddsdasdasd')
        this.editId = null
        house.flats = house.flats.filter((item) => item.length)
      },
      openDoor(id) {
        API.openDoor({ deviceId: id })
          .then((res) => {
            console.log(res)
            Constants.alert.fire('Открытие двери', 'Успешно', 'success')
          })
          .catch((e) => {
            Constants.alert.fire('Ошибка', e, 'error')
          })
      },
      loadPage() {
        API.getHousesList({ id: this.$route.params.id && this.$route.params.id })
          .then((res) => {
            console.log(res.data, 'houses')
            this.houseOptions = res.data.map((item) => {
              return {
                id: item.id,
                text: item.address,
                ...item,
              }
            })
            if (this.houseOptions.length === 1) {
              this.selectedHouse = this.houseOptions[0].id
            }
            if (!this.housesList.length) {
              this.housesList = res.data.map((item) => {
                return {
                  id: item.id,
                  text: item.address,
                  ...item,
                }
              })
            }
          })
          .catch((e) => console.log(e))
        API.getFlats({ id: this.selectedHouse })
          .then((res) => {
            console.log(res)
            this.flatsList = res.data
          })
          .catch((e) => console.log(e))
        API.getComplexById(this.id)
          .then((response) => {
            this.complex = response.data
          })
          .catch((error) => {
            console.log(error)
            this.close()
          })
        API.getDevicesByComplexId(this.id)
          .then((response) => {
            this.devices = response.data
          })
          .catch((error) => {
            console.log(error)
            this.close()
          })
      },
      isActive(menuItem) {
        return this.activeItem === menuItem
      },
      setActive(menuItem) {
        this.activeItem = menuItem
      },
      onPageChange() {
        this.loadPage()
      },
      close() {
        this.$router.push({ path: this.backUrl })
      },
      addDevice() {
        this.editedDevice = null
        this.editDeviceModal = true
      },
      editDevice(id) {
        this.editedDevice = id
        this.editDeviceModal = true
      },
      deleteDevice(id) {
        const self = this
        Constants.alert.fire(Constants.confirmOptions.delete).then((res) => {
          if (res.isConfirmed) {
            API.deleteDevice(id)
              .then(() => {
                Constants.alert.fire('Удаление устройства', 'Успешно', 'success')
                self.loadPage()
              })
              .catch((error) => {
                Constants.alert.fire('Ошибка удаления устройства', error.response.data, 'error')
              })
          }
        })
      },
      closeEditDeviceModal() {
        this.editDeviceModal = false
        this.loadPage()
      },
      issuanceCertificate(id) {
        console.log(id, 'id')
        API.issuanceCertificate({ id: id })
          .then((res) => {
            console.log(res)
            Constants.alert.fire('Сертификат выпущен', res, 'success')
            this.loadPage()
          })
          .catch((error) => {
            Constants.alert.fire('Ошибка', error.message, 'error')
          })
      },
      exportCeritficate(id) {
        API.exportCertificate({ deviceId: id })
          .then((res) => {
            console.log(res)
          })
          .catch((error) => {
            Constants.alert.fire('Ошибка, нужно выпустить сертификат', error, 'error')
          })
      },
      changeUpdate(device) {
        if (!device.allowUpdate) device.allowUpdate = true
        else {
          return
        }
        API.saveDevice(device).catch((e) => {
          console.log(e)
          device.allowUpdate = false
        })
      },
      saveAllowUpdateForDevices() {
        const self = this
        API.saveAllowUpdateForDevices(self.id).then(() => {
          self.loadPage()
        })
      },
      saveComplex() {
        const data = {
          complexId: this.complex.id,
          secureOwnerKey: this.complex.secureOwnerKey,
          secureAdminKey: this.complex.secureAdminKey,
          secureOperatorKey: this.complex.secureOperatorKey,
          secureKeyMode: this.complex.secureKeyMode,
        }
        API.saveComplex(data)
          .then(() => {
            Constants.alert.fire('Сохранение', '', 'success')
            this.loadPage()
          })
          .catch((error) => {
            Constants.alert.fire('Ошибка сохранения', error.response.data, 'error')
          })
      },
      changeTempCode(flat) {
        flat.tempCode = {
          houseId: flat.houseId,
          validToNumberOfPasses: 1,
          validTo: null,
        }
      },
      generateTempCode(flat) {
        const tempCode = flat.tempCode
        flat.tempCode = null
        if (tempCode.validTo) {
          tempCode.validTo = moment(tempCode.validTo).utc()
        }
        tempCode.flatNumber = flat.number
        API.generateTempCode(tempCode)
          .then((resp) => {
            Constants.alert.fire('Генерация временного кода', resp.data, 'success')
            //self.loadPage()
          })
          .catch((error) => {
            Constants.alert.fire('Ошибка генерации временного кода', error.response.data, 'error')
          })
      },
    },
    beforeRouteEnter(to, from, next) {
      console.log(to)
      next((vm) => {
        vm.backUrl = from.path
      })
    },
    setup: () => ({ v$: useVuelidate() }),
  }
</script>

<style lang="scss" scoped>
  @import '/src/assets/theme/default/colors.scss';
  @import '/src/common/index';
  .flat {
    margin-bottom: 1rem;
    border-bottom: 1px solid #d7d7e0;
  }
  .nav-tabs {
    .nav-item {
      .nav-link {
        padding: 14.5px 34.5px;
        text-align: center;
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 120%;
        color: #7f7f82;
        border: 1px solid #d7d7e0;
        box-sizing: border-box;
        border-radius: 6px;
      }
    }
  }

  .no-search-box .select2-drop {
    .select2-search {
      display: none;
    }
  }

  .w100 .select2-container {
    width: 100% !important;
  }
  .select2::v-deep {
    .select2-container {
      width: 100% !important;
      max-width: 100% !important;
    }

    .select2-container--default .select2-selection--single {
      height: calc(2.25rem + 2px) !important;
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow {
      top: 7px !important;
    }
  }
</style>
