import Devices from '../views/Devices.vue'
import DeviceTypeCard from '../views/DeviceTypeCard.vue'
import DevicesWrapper from '@/modules/devices/views/DevicesWrapper'

export const routes = [
  {
    path: '/devices',
    name: 'DevicesWrapper',
    component: DevicesWrapper,
    children: [
      {
        path: '/devices',
        name: 'Devices',
        component: Devices,
      },
      {
        path: '/devices/:id',
        name: 'DeviceTypeCard',
        component: DeviceTypeCard,
      },
    ],
  },
]
