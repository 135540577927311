<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>Поиск организации</label>
              <div>
                <input class="form-control" v-model="currFilters.search" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer d-flex">
        <button class="btn btn-primary" v-on:click="apply">Применить</button>
        <button class="btn btn-default" v-on:click="reset">Сбросить</button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'OrganizationsFilter',
    components: {},
    props: {
      filters: Object,
      adminMode: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        currFilters: this.filters,
      }
    },
    created() {},
    methods: {
      apply() {
        const filters = Object.assign({}, this.currFilters)
        this.$emit('apply', filters)
      },
      reset() {
        this.currFilters = {
          search: '',
        }
        const filters = Object.assign({}, this.currFilters)
        this.$emit('reset', filters)
      },
    },
  }
</script>

<style>
  .select2-container--default .select2-selection--single {
    height: calc(2.25rem + 2px) !important;
  }
  .select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 7px !important;
  }
</style>
