<template>
  <button :class="['button-component', ...buttonClasses]">
    <slot name="prepend" />
    <slot />
    <slot name="append" />
    <span v-show="loading" class="spinner-border spinner-border-sm ml-1" />
  </button>
</template>

<script>
  export default {
    name: 'ButtonComponent',
    props: {
      loading: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      kind: {
        type: String,
        required: false,
        default: 'primary',
        validator: (kind) => {
          return ['primary', 'secondary', 'tertiary', 'danger', 'link'].indexOf(kind) > -1
        },
      },
    },
    computed: {
      buttonClasses() {
        let list = []
        if (this.kind) {
          list.push(`button-component_${this.kind}`)
        }
        if (this.disabled) {
          list.push(`button-component_disabled`)
        }
        return list
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import 'src/assets/theme/default/colors';
  .button-component {
    padding: 7px 32px;
    border-radius: 32px;
    transition: all 0.2s ease;
    cursor: pointer;
    border: 1px solid transparent;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;

    &_primary:not(&_disabled) {
      color: $white;
      background-color: $blue-light;
      border-color: $blue-light;
      &:hover {
        color: $white;
        background-color: $blue;
        border-color: $blue;
      }
    }
    &_secondary:not(&_disabled) {
      color: $blue-light;
      background-color: transparent;
      border-color: $blue-light;
      &:hover {
        color: $blue;
        background-color: transparent;
        border-color: $blue;
      }
    }
    &_tertiary:not(&_disabled) {
      color: $blue-light;
      background-color: transparent;
      border-color: transparent;
      &:hover {
        color: $blue;
        background-color: transparent;
        border-color: transparent;
      }
    }
    &_disabled {
      color: $black;
      background-color: $gray-light2;
      border-color: $gray-light2;
      pointer-events: none;
      &:hover {
        cursor: not-allowed !important;
      }
    }
  }
</style>
