// Для возможности конфигурации без пересборки проекта в index.html подключен скрипт /js/runtime-config.js,
// который выполняется перед скриптом приложения, и в котором можно определить объект window.runtimeConfig.

const configOverrides = window.runtimeConfig || {}

const config = {
  apiBaseUrl: process.env.VUE_APP_API_BASE_URL,
}

export default Object.assign(config, configOverrides)
