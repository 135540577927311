import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'jquery/dist/jquery.min'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'admin-lte/dist/js/adminlte.min'
import 'admin-lte/dist/css/adminlte.min.css'
require('@/assets/theme/default/style.scss')
import moment from 'moment'
import { configAxios } from '@/common/axios'
import EmptyListMessage from '@/common/components/EmptyListMessage'
import Pagination from '@/common/components/Pagination'
import Select2 from 'vue3-select2-component'
import 'select2/dist/js/i18n/ru'
import datepicker from 'vue3-datepicker'
import 'bootstrap/dist/css/bootstrap.css'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'
configAxios()
const app = createApp(App).use(store).use(router)
app.config.globalProperties.$momentFormat = (date, format) => (date != null ? moment(date).format(format) : '')
app.component('EmptyListMessage', EmptyListMessage)
app.component('Pagination', Pagination)
app.component('Select2', Select2)
app.component('Datepicker', datepicker)
app.mount('#app')
