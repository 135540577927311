import Complexes from '../views/Complexes.vue'
import ComplexCard from '../views/ComplexCard.vue'
import ComplexesWrapper from '@/modules/complex/views/ComplexesWrapper'

export const routes = [
  {
    path: '/complexes',
    name: 'ComplexesWrapper',
    component: ComplexesWrapper,
    children: [
      {
        path: '/complexes',
        name: 'Complexes',
        component: Complexes,
      },
      {
        path: '/complexes/:action/:id',
        name: 'cardComplex',
        component: ComplexCard,
        props: true,
      },
      {
        path: '/complexes/:action',
        name: 'addComplex',
        component: ComplexCard,
        props: true,
      },
    ],
  },
]
