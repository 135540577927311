<template>
  <tr>
    <td class="action-td"></td>
    <td :style="padding" class="actions">
      <div class="d-flex">
        <div style="width: 25px">
          <i class="fas fa-caret-down" v-if="item.childParameters.rows?.length" @click="showChildren" />
        </div>
        <div>
          {{ item.columns.name }}
        </div>
      </div>
    </td>
    <td>
      {{ item.columns.description }}
    </td>
    <td class="actions">
      <i class="fas fa-pen" @click="$emit('edit-param', item)" />
      <i class="fas fa-plus text-success" @click="$emit('append-child', item)" />
      <i class="fas fa-trash-alt text-danger" @click="$emit('remove-param', item)" />
    </td>
  </tr>
  <template v-if="item.childParameters.rows?.length && isShownChildren">
    <example-row-component
      :level="level + 1"
      v-for="(row, index) in item.childParameters.rows"
      v-bind="$attrs"
      :key="index"
      :item="row"
    />
  </template>
</template>

<script>
  export default {
    name: 'ExampleRowComponent',
    inheritAttrs: false,
    props: {
      item: {
        type: Object,
        required: true,
      },
      level: {
        type: Number,
        default: 0,
      },
    },
    data: () => ({
      isShownChildren: true,
    }),
    computed: {
      padding() {
        return `padding-left: ${this.level * 15}px`
      },
    },
    methods: {
      showChildren() {
        this.isShownChildren = !this.isShownChildren
      },
    },
  }
</script>

<style lang="scss" scoped>
  .action-td {
    width: 20px;
  }
  .actions {
    .fas {
      padding: 8px;
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
  .arrow {
    white-space: nowrap;
  }
</style>
