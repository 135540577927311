<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h2 v-if="!id">Добавление устройства</h2>
        <h2 v-if="id && device">{{ device.name }}</h2>
        <div v-if="device">
          <div class="row mt-10">
            <div class="col-md-12">
              <div class="form-group required">
                <label class="control-label">Название устройства</label>
                <input class="form-control required" v-model="device.name" />
              </div>
            </div>
          </div>
          <div class="row mt-10">
            <div class="col-md-12">
              <div class="form-group">
                <label class="control-label">Описание устройства</label>
                <input class="form-control" v-model="device.description" />
              </div>
            </div>
          </div>
          <div class="row mt-10">
            <div class="col-md-12">
              <div class="form-group required">
                <label class="control-label">Серийный номер</label>
                <input class="form-control required" v-model="device.serialNumber" />
              </div>
            </div>
          </div>
          <div class="row mt-10">
            <div class="col-md-12">
              <div class="form-group required">
                <label class="control-label">Тип устройства</label>
                <div>
                  <!--Не используется Select2, так как он не работает в модалке-->
                  <select
                    placeholder="Не указано"
                    :options="deviceTypesList"
                    v-model="device.deviceTypeId"
                    class="w100 select2"
                    :settings="select2Settings"
                    required
                    @change="onChangeDeviceType"
                  >
                    <option v-for="option in deviceTypesList" v-bind:key="option.id" v-bind:value="option.id">
                      {{ option.text }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-10">
            <div class="col-md-12">
              <div class="form-group required">
                <label class="control-label">Дома, с которыми связано устройство</label>
                <div>
                  <!--Не используется Select2, так как он не работает в модалке-->
                  <select
                    placeholder="Выберите дома"
                    :options="housesList"
                    v-model="device.houseIds"
                    class="w100 select2"
                    :settings="select2Settings"
                    multiple
                    required
                  >
                    <option v-for="house in housesList" v-bind:key="house.id" v-bind:value="house.id">
                      {{ house.text }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-10">
            <div class="col-md-12">
              <div class="form-check">
                <input type="checkbox" class="form-check-input" v-model="device.allowUpdate" />
                <label>Разрешить обновление</label>
              </div>
            </div>
          </div>
          <!-- <div class="row mt-10" v-if="selectedDeviceType">
            <div class="col-md-12">
              <div class="form-group required">
                <label class="control-label">Ревизия</label>
                <div>
                  <select
                    placeholder="Не указано"
                    :options="selectedDeviceType.revisions"
                    v-model="device.revisionId"
                    class="w100 select2"
                    :settings="select2Settings"
                    required
                    @change="onChangeRevision"
                  >
                    <option
                      v-for="option in selectedDeviceType.revisions"
                      v-bind:key="option.id"
                      v-bind:value="option.id"
                    >
                      {{ option.number }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-10" v-if="selectedDeviceType">
            <div class="col-md-12">
              <div class="form-group required">
                <label class="control-label">Номер ревизии</label>
                <div>
                  <select
                    placeholder="Не указано"
                    :options="revisionNumberList"
                    v-model="device.revisionVersionId"
                    class="w100 select2"
                    :settings="select2Settings"
                    required
                  >
                    <option v-for="option in revisionNumberList" v-bind:key="option.id" v-bind:value="option.id">
                      {{ option.number }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div> -->
          <br />
          <label class="control-label">Настройки камеры</label>
          <div v-if="id">
            <div class="row mt-10">
              <div class="col-md-3">
                <div class="form-group">
                  <label class="control-label">Название</label>
                  <input class="form-control" v-model="ipcam.name" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label class="control-label">Mac-адрес</label>
                  <input class="form-control" v-model="ipcam.mac" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label class="control-label">Ip-адрес</label>
                  <input class="form-control" v-model="ipcam.ip" />
                </div>
              </div>
              <div class="col-md-3">
                <button-component class="mr-2" @click="getIp">Получить</button-component>
              </div>
            </div>
            <div class="row mt-10">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">Описание</label>
                  <input class="form-control" v-model="ipcam.description" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">Rtsp-server</label>
                  <div>
                    <!--Не используется Select2, так как он не работает в модалке-->
                    <select
                      placeholder="Не указано"
                      :options="rtspServers"
                      v-model="ipcam.rtspServerId"
                      class="w100 select2"
                      :settings="select2Settings"
                      required
                      @change="onChangeDeviceType"
                    >
                      <option v-for="option in rtspServers" v-bind:key="option.id" v-bind:value="option.id">
                        {{ option.text }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-10">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">
                    Логин доступа к камере. Если не задан, то берется из настроек сервера
                  </label>
                  <input class="form-control" v-model="ipcam.innerLogin" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">
                    Пароль доступа к камере. Если не задан, то берется из настроек сервера
                  </label>
                  <input class="form-control" v-model="ipcam.innerPassword" />
                </div>
              </div>
            </div>
            <div class="row mt-10" v-if="ipcam.id">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">Логин</label>
                  <input class="form-control" v-model="ipcam.outerLogin" disabled />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">Пароль</label>
                  <input class="form-control" v-model="ipcam.outerPassword" disabled />
                </div>
              </div>
            </div>
            <div class="row mt-10" v-if="ipcam.id">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">Скорость</label>
                  <input class="form-control" v-model="ipcam.speed" disabled />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">Интерфейс</label>
                  <input class="form-control" v-model="ipcam.type_Iface" disabled />
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-10" v-if="ipcam.id">
            <div class="col-md-6">
              <div class="form-group">
                <label class="control-label">Url full-hd</label>
                <input class="form-control" v-model="ipcam.bigStreamUrl" disabled />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="control-label">Url small</label>
                <input class="form-control" v-model="ipcam.smallStreamUrl" disabled />
              </div>
            </div>
          </div>
          <div class="row mt-10" v-else>
            <div>Для настройки камеры сохраните, пожалуйста, устройство</div>
          </div>
          <br />
          <label class="control-label">Ключи</label>
          <div class="row mt-10" v-if="this.id">
            <div>
              <a class="ml-2" href="#" title="Добавить ключи" v-if="!editedKey">
                <i @click="addFlat()" class="fas fa-plus text-success" />
              </a>
            </div>
            <div v-if="id" class="table-responsive">
              <table class="table-custom table">
                <thead>
                  <tr>
                    <th class="col-table-2">Ключи</th>
                    <th class="col-table-2">Дом</th>
                    <th class="col-table-2">Квартира (опционально)</th>
                    <th class="col-table-2"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, flatidx) in deviceKeys" :key="item.id">
                    <td>
                      <div>
                        <a class="mb-4 d-block" href="#" title="Добавить ключ" v-if="!addingKey && !editedKey">
                          <i @click="addKey(item)" class="fas fa-plus text-success" />
                        </a>
                        <div class="mb-2" v-for="(flatKey, idx) in item.keys" :key="idx">
                          <input
                            v-model="editedKeyValueNew"
                            type="text"
                            v-if="editedKey && editedKey.id === item.id && editedKeyValue === flatKey"
                          />
                          <strong
                            v-if="
                              !editedKey ||
                              editedKey.id !== item.id ||
                              (editedKey.id === item.id && editedKeyValue !== flatKey)
                            "
                          >
                            {{ flatKey }}
                          </strong>
                          <a
                            v-if="
                              (!editedKey ||
                                editedKey.id !== item.id ||
                                (editedKey.id === item.id && editedKeyValue !== flatKey)) &&
                              !addingKey
                            "
                            class="ml-2"
                            href="#"
                            title="Редактировать ключ"
                          >
                            <i @click="editKey(item, flatKey)" class="fas fa-pen text-gray-dark ml-2" />
                          </a>
                          <a
                            v-if="editedKey && editedKey.id && editedKey.id === item.id && editedKeyValue === flatKey"
                            class="ml-2"
                            href="#"
                            title="Сохранить ключ"
                          >
                            <i @click="saveKeys()" class="fas fa-check text-success ml-2" />
                          </a>
                          <a
                            title="Отмена"
                            class="btn"
                            @click="cancelEdit()"
                            v-if="editedKey && editedKey.id === item.id && editedKeyValue === flatKey && !addingKey"
                          >
                            <i class="fas fa-undo text-danger" />
                          </a>
                          <a title="Удалить" class="btn" @click="deleteKey(item, idx)">
                            <i class="fas fa-trash text-danger" />
                          </a>
                        </div>
                      </div>
                    </td>
                    <td v-if="!editedKey || editedKey.id !== item.id">
                      {{ item.houseId ? housesList.find((el) => el.id === item.houseId).text : '' }}
                    </td>
                    <td v-else>
                      <select
                        placeholder="Выберите дом"
                        :options="selectedHousesList"
                        v-model="item.houseId"
                        class="w100 select2"
                        :settings="select2Settings"
                      >
                        <option v-for="house in selectedHousesList" v-bind:key="house.id" v-bind:value="house.id">
                          {{ house.text }}
                        </option>
                      </select>
                    </td>
                    <td v-if="!editedKey || editedKey.id !== item.id">{{ item.flatNumber }}</td>
                    <td v-else><input v-model="item.flatNumber" type="number" min="1" /></td>
                    <td>
                      <a title="Удалить" class="btn" @click="deleteFlat(item.id, flatidx)">
                        <i class="fas fa-trash text-danger" />
                      </a>
                      <a v-if="editedKey && !editedKey.id" class="ml-2" href="#" title="Сохранить набор ключей">
                        <i @click="saveNewFlatKeys()" class="fas fa-check text-success ml-2" />
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row mt-10" v-else>
            <div>Для ввода ключей, сохраните, пожалуйста, устройство</div>
          </div>
          <br />
          <label class="control-label">Параметры</label>
          <div class="row mt-10">
            <div class="col-md-12">
              <div class="form-group required">
                <vue3-json-editor
                  v-model="device.parameters"
                  :show-btns="false"
                  :expandedOnStart="true"
                  @json-change="onJsonChange"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="float-right d-flex">
          <button-component class="mr-2" @click="save">Сохранить</button-component>
          <button-component kind="tertiary" @click="onClose">Закрыть</button-component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Constants from '@/common/constants'
  import API from '@/common/api/index'
  import { Vue3JsonEditor } from 'vue3-json-editor'
  import ButtonComponent from '@/common/ui/ButtonComponent'

  export default {
    name: 'DeviceCard',
    components: {
      Vue3JsonEditor,
      ButtonComponent,
    },
    props: {
      id: {
        type: String,
        required: false,
      },
      intercomComplexId: {
        type: String,
        required: true,
      },
      onClose: Function,
    },
    data() {
      return {
        device: null,
        select2Settings: Constants.select2Settings,
        deviceTypes: [],
        deviceTypesList: [],
        housesList: [],
        selectedHousesList: [],
        selectedDeviceType: null,
        revisionNumberList: [],
        deviceKeys: [],
        editedKey: null,
        editedKeyValue: null,
        editedKeyValueNew: null,
        addingKey: false,
        ipcam: {},
        rtspServers: [],
      }
    },
    created() {
      this.loadPage()
    },
    methods: {
      loadPage() {
        const self = this
        API.getDeviceTypes()
          .then((response) => {
            self.deviceTypes = response.data
            response.data.forEach((type) => {
              self.deviceTypesList.push({
                id: type.id,
                text: type.name,
              })
            })
          })
          .catch((error) => {
            console.log(error)
          })
        API.getHousesList({ id: this.intercomComplexId })
          .then((res) => {
            this.housesList = res.data.map((item) => {
              return {
                id: item.id,
                text: item.address,
              }
            })
            if (this.id) {
              API.getDeviceById(this.id)
                .then((response) => {
                  self.device = response.data
                  if (self.id && !self.selectedDeviceType) {
                    self.onChangeDeviceType()
                  }
                  console.log(self.device, '----------dev')
                  if (self.device.houseIds) {
                    var ids = self.device.houseIds
                    console.log(ids)
                    self.selectedHousesList = self.housesList.filter((v) => ids.find((f) => f == v.id))
                  }
                  self.ipcam = self.device.ipCams[0] ?? { deviceId: this.id }
                })
                .catch((error) => {
                  console.log(error)
                })
            }
          })
          .catch((e) => console.log(e))
        API.getRtspList()
          .then((response) => {
            response.data.forEach((type) => {
              this.rtspServers.push({
                id: type.id,
                text: type.name,
              })
            })
          })
          .catch((error) => {
            console.log(error)
          })
        if (this.id) {
          API.getDeviceKeys(this.id)
            .then((response) => {
              self.deviceKeys = response.data.sort((l, r) => {
                if (l.flatNumber < r.flatNumber) {
                  return -1
                }
                if (l.flatNumber > r.flatNumber) {
                  return 1
                }
                return 0
              })
            })
            .catch((error) => {
              console.log(error)
            })
        } else this.device = { intercomComplexId: this.intercomComplexId }
      },
      getIp() {
        API.getIpCamNextIp().then((res) => {
          this.ipcam.ip = res.data
        })
      },
      save() {
        if (this.id) {
          API.saveDevice(this.device)
            .then(() => {
              if (this.ipcam.name && this.ipcam.name.length > 0) {
                API.saveIpCam(this.ipcam)
                  .then(() => {
                    this.onClose()
                    Constants.alert.fire('Сохранение устройства', 'Успешно', 'success')
                  })
                  .catch((error) => {
                    Constants.alert.fire('Ошибка создания устройства', error.response.data, 'error')
                  })
              } else {
                Constants.alert.fire('Сохранение устройства', 'Успешно', 'success')
              }
            })
            .catch((error) => {
              Constants.alert.fire('Ошибка сохранения устройства', error.response.data, 'error')
            })
        } else {
          API.createDevice(this.device)
            .then(() => {
              this.onClose()
              Constants.alert.fire('Создание устройства', 'Успешно', 'success')
            })
            .catch((error) => {
              Constants.alert.fire('Ошибка создания устройства', error.response.data, 'error')
            })
        }
      },
      onJsonChange(parameters) {
        this.device.parameters = parameters
      },
      onChangeDeviceType() {
        const self = this
        API.getDeviceTypeById(self.device.deviceTypeId)
          .then((response) => {
            self.selectedDeviceType = response.data
            self.onChangeRevision()
          })
          .catch((e) => console.log(e))
      },
      onChangeRevision() {
        this.revisionNumberList = this.selectedDeviceType.revisions
          .find((r) => r.id === this.device.revisionId)
          .versions.sort((l, r) => {
            if (l.number < r.number) {
              return -1
            }
            if (l.number > r.number) {
              return 1
            }
            return 0
          })
      },
      editKey(item, editedKeyValue) {
        this.editedKey = item
        this.editedKeyValue = editedKeyValue
        this.editedKeyValueNew = editedKeyValue
        this.addingKey = false
      },
      saveKeys() {
        debugger
        if (!this.editedKeyValueNew.length) {
          return
        }
        this.addingKey = false
        const self = this
        const index = this.editedKey.keys.findIndex((curVal) => curVal === this.editedKeyValue)
        this.editedKey.keys[index] = this.editedKeyValueNew
        API.updateDeviceKeys(self.editedKey)
          .then(() => {
            Constants.alert.fire('Обновление ключей', 'Успешно', 'success')
            self.editedKey = null
            self.editedKeyValueNew = ''
            self.editedKeyValue = ''
            self.loadPage()
          })
          .catch((e) => {
            Constants.alert.fire('Ошибка', e, 'error')
          })
      },
      cancelEdit() {
        this.editedKey = null
        this.editedKeyValueNew = ''
        this.editedKeyValue = ''
        this.addingKey = false
      },
      deleteKey(item, idx) {
        item.keys.splice(idx, 1)
        if (this.addingKey) {
          this.addingKey = false
          return
        }
        API.updateDeviceKeys(item)
          .then(() => {
            Constants.alert.fire('Удаление', 'Успешно', 'success')
            this.cancelEdit()
            this.loadPage()
          })
          .catch((e) => {
            Constants.alert.fire('Ошибка', e, 'error')
          })
      },
      deleteFlat(id, idx) {
        this.deviceKeys.splice(idx, 1)
        if (!id) this.cancelEdit()
        else {
          API.deleteDeviceKeys(id)
            .then(() => {
              Constants.alert.fire('Удаление связуи ключей', 'Успешно', 'success')
              this.cancelEdit()
              this.loadPage()
            })
            .catch((e) => {
              Constants.alert.fire('Ошибка', e, 'error')
            })
        }
      },
      addKey(item) {
        item.keys.unshift('')
        this.editedKey = item
        this.editedKeyValue = ''
        this.editedKeyValueNew = ''
        this.addingKey = true
      },
      addFlat() {
        this.editedKey = {
          id: null,
          deviceId: this.id,
          houseId: null,
          flatNumber: null,
          keys: [],
        }
        this.addKey(this.editedKey)
        this.deviceKeys.unshift(this.editedKey)
      },
      saveNewFlatKeys() {
        debugger
        if (!this.editedKey || this.editedKey.id) {
          return
        }
        this.editedKey.keys = [this.editedKeyValueNew]
        API.createDeviceKeys(this.editedKey)
          .then(() => {
            Constants.alert.fire('Создание набора ключей', 'Успешно', 'success')
            this.cancelEdit()
            this.loadPage()
          })
          .catch((e) => {
            Constants.alert.fire('Ошибка', e.response.data, 'error')
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '/src/assets/theme/default/colors.scss';
  @import '/src/common/index';

  .no-search-box .select2-drop {
    .select2-search {
      display: none;
    }
  }

  .w100 .select2-container {
    width: 100% !important;
  }
  .select2::v-deep {
    .select2-container {
      width: 100% !important;
      max-width: 100% !important;
    }

    .select2-container--default .select2-selection--single {
      height: calc(2.25rem + 2px) !important;
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow {
      top: 7px !important;
    }
  }
</style>
