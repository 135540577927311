<template>
  <section class="d-flex align-items-center flex-column">
    <h1 class="text-white">Такой страницы нет</h1>
    <button-component @click="$router.replace({ name: 'Home' })">На главную</button-component>
  </section>
</template>

<script>
  import ButtonComponent from '@/common/ui/ButtonComponent'
  export default {
    name: 'NotFound',
    components: { ButtonComponent },
  }
</script>
