import axios from 'axios'
import utils from '@/common/utils'

export default {
  getHouse: function (request) {
    return axios.get(`/api/v1/House/${request}`)
  },
  getFlats: function (request) {
    return axios.get(`/api/v1/House/${request.id}/flats`)
  },
  createFlat: function (request) {
    return axios.post(`/api/v1/House/flat`, request, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  getHousesList: function (request) {
    return axios.get(`/api/v1/House/intercom-complex/${request.id}`)
  },
  openDoor: function (request) {
    return axios.post(`api/v1/device/command/open-door`, request.deviceId, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  exportCertificate: function (request) {
    return utils.downloadFile(`/api/v1/Device/${request.deviceId}/certificate/export`)
  },

  issuanceCertificate: function (request) {
    return axios.post(`/api/v1/Device/issued-certificate`, request.id, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  saveHistoryLog: function (request) {
    return axios.put(`/api/v1/Device/event-history-settings`, {
      daysCount: request.daysCount,
      intercomComplexId: request.intercomComplexId,
    })
  },
  getEventRegistryList: function (request) {
    return axios.get('/api/v1/Device/events', {
      params: request,
    })
  },
  getCompanyList: function (request) {
    return axios.get('/api/v1/Company', { params: request })
  },
  getAllCompanyList: function () {
    return axios.get('/api/v1/Company/list')
  },
  getComplexList: function (request) {
    return axios.get('/api/v1/intercomComplex', { params: request })
  },
  getComplexById: (id) => {
    return axios.get('/api/v1/IntercomComplex/' + id)
  },
  getDevicesByComplexId: (id) => {
    return axios.get('/api/v1/Device/by-intercom-complex/' + id)
  },
  getDeviceById: (id) => {
    return axios.get('/api/v1/Device/' + id)
  },
  getDeviceTypes: () => {
    return axios.get('/api/v1/DeviceType')
  },
  getDeviceTypeById: (id) => {
    return axios.get(`/api/v1/DeviceType/${id}`)
  },
  saveDevice: (device) => {
    return axios.put('/api/v1/Device', device)
  },
  saveAllowUpdateForDevices: (intercomComplexId) => {
    return axios.put(`/api/v1/Device/allow-update?complexId=${intercomComplexId}`)
  },
  createDevice: (device) => {
    return axios.post('/api/v1/Device', device)
  },
  deleteDevice: (id) => {
    return axios.delete('/api/v1/Device/' + id)
  },
  generateTempCode: (tempCode) => {
    return axios.post('/api/v1/House/generate-temporary-code', tempCode)
  },
  registerLicenseOnline: (data) => {
    return axios.post('/api/v1/License/register/online', data)
  },
  generateLicenseRequest: (data) => {
    return axios.post('/api/v1/License/register/offline', data, { responseType: 'blob' }).then((response) => {
      const blob = new Blob([response.data], { type: response.data.type })
      const link = document.createElement('a')
      const contentDisposition = response.headers['content-disposition']
      let filename = 'download'
      if (contentDisposition) {
        const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-\\.]+)(?:; ?|$)/i
        filename = decodeURIComponent(utf8FilenameRegex.exec(contentDisposition)[1])
      }
      link.href = URL.createObjectURL(blob)
      link.download = filename
      link.click()
      URL.revokeObjectURL(link.href)
    })
  },
  loadLicense: (file) => {
    let formData = new FormData()
    formData.append('file', file)
    return axios.post('/api/v1/License/loadlicense', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  syncDeviceTypes: () => {
    return axios.post('/api/v1/Settings/device-type-schedule/refresh')
  },
  syncComplexes: () => {
    return axios.post('/api/v1/Settings/complex-sync-schedule/refresh')
  },
  getBasicAuthSettings: () => {
    return axios.get('/api/v1/Settings/basic-auth')
  },
  saveBasicAuthSettings: (settings) => {
    return axios.post('/api/v1/Settings/basic-auth', settings)
  },
  getDeviceTypeScheduleSettings: () => {
    return axios.get('/api/v1/Settings/device-type-schedule')
  },
  saveDeviceTypeScheduleSettings: (data) => {
    return axios.post('/api/v1/Settings/device-type-schedule', data)
  },
  refreshDeviceTypeSync: () => {
    return axios.post('/api/v1/Settings/device-type-schedule/refresh')
  },
  getUserFlatSyncScheduleSettings: () => {
    return axios.get('/api/v1/Settings/user-flat-sync-schedule')
  },
  saveUserFlatSyncScheduleSettings: (data) => {
    return axios.post('/api/v1/Settings/user-flat-sync-schedule', data)
  },
  getIpCamSettings: () => {
    return axios.get('/api/v1/Settings/ip-cam-range')
  },
  getIpCamCrendSettings: () => {
    return axios.get('/api/v1/Settings/ip-cam-default-inner-credentials')
  },
  saveIpCamSettings: (data) => {
    return axios.post('/api/v1/Settings/ip-cam-range', data)
  },
  saveIpCamCrendSettings: (data) => {
    return axios.post('/api/v1/Settings/ip-cam-default-inner-credentials', data)
  },
  refreshUserFlatSync: () => {
    return axios.post('/api/v1/Settings/user-flat-sync-schedule/refresh')
  },
  getHousesSyncScheduleSettings: () => {
    return axios.get('/api/v1/Settings/houses-sync-schedule')
  },
  saveHousesSyncScheduleSettings: (data) => {
    return axios.post('/api/v1/Settings/houses-sync-schedule', data)
  },
  refreshHousesSync: () => {
    return axios.post('/api/v1/Settings/houses-sync-schedule/refresh')
  },
  getComplexSyncScheduleSettings: () => {
    return axios.get('/api/v1/Settings/complex-sync-schedule')
  },
  saveComplexSyncScheduleSettings: (data) => {
    return axios.post('/api/v1/Settings/complex-sync-schedule', data)
  },
  refreshComplexSync: () => {
    return axios.post('/api/v1/Settings/complex-sync-schedule/refresh')
  },
  getDeviceKeys: (deviceId) => {
    return axios.get(`/api/v1/Device/${deviceId}/keys`)
  },
  createDeviceKeys: (data) => {
    return axios.post('/api/v1/Flat', data)
  },
  updateDeviceKeys: (data) => {
    return axios.put('/api/v1/Flat', data)
  },
  deleteDeviceKeys: (keysId) => {
    return axios.delete('/api/v1/Flat?id=' + keysId)
  },
  saveUser: (data) => {
    return axios.post('/api/v1/User', data)
  },
  updateUser: (data) => {
    return axios.put('/api/v1/User', data)
  },
  deleteUser: (id) => {
    return axios.delete(`/api/v1/User/${id}`)
  },
  getRtspList: () => {
    return axios.get(`/api/v1/RtspServer`)
  },
  getIpCamNextIp: () => {
    return axios.get(`/api/v1/ipcam/available-addresses/next`)
  },
  saveIpCam: (data) => {
    if (data.id) {
      return axios.put('/api/v1/ipcam', data)
    } else {
      return axios.post('/api/v1/ipcam', data)
    }
  },
  saveComplex: (data) => {
    return axios.put(`/api/v1/IntercomComplex`, data)
  },
}
