<template>
  <nav class="mt-2">
    <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
      <li class="nav-item" v-for="item in nodes" :key="item.title">
        <tree-node :is-collapsed="isCollapsed" :node="item"></tree-node>
      </li>
    </ul>
  </nav>
</template>

<script>
  import TreeNode from './TreeNode'

  export default {
    name: 'TreeView',
    components: { TreeNode },
    props: {
      nodes: Array,
      isCollapsed: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>
